import { featureIsEnabled } from '../../../globals/envSettings';

const getOrgLicencesHeaderItems = (
  content,
  { openAddLicensesModalAction = null },
  { name = '' },
  isOrgPlacementCentre
) =>
  featureIsEnabled('opt-main-features')
    ? [
        {
          name,
          status: isOrgPlacementCentre
            ? content.org_licences_header_assign_available
            : content.org_licences_header_licences,
          action: isOrgPlacementCentre ? null : openAddLicensesModalAction,
          used: isOrgPlacementCentre ? content.org_licences_header_used : null,
          buttonText: isOrgPlacementCentre ? content.buy_licences_button : content.add
        }
      ]
    : [
        {
          name,
          status: content.org_licences_header_licences,
          action: openAddLicensesModalAction,
          buttonText: content.add
        }
      ];
export default getOrgLicencesHeaderItems;
