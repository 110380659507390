import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { ADD_SUPERVISED_USERS_LINK, COOKIES_MAX_AGE } from '@oup/shared-node-browser/constants';
import styles from './SupervisedAdminInformationModal.scss';
import HubModal from '../HubModal/HubModal';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import Illustration from '../Illustration/Illustration';
import cookies from '../../utils/cookies';
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';

function SupervisedAdminInformationModal({ hubContent, userId, userRole }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!userId) {
      return;
    }
    const infoCookie = cookies.get(`${userId}-info-accepted`);
    if (!infoCookie && roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole)) {
      setIsOpen(true);
    }
  }, [userId]);

  return (
    <HubModal
      isOpen={isOpen}
      onClose={() => {
        cookies.set(`${userId}-info-accepted`, true, {
          path: '/',
          maxAge: COOKIES_MAX_AGE
        });
        setIsOpen(false);
      }}
      closeButtonId="SUPERVISED_ADMIN_INFO_MODAL_CLOSE_BUTTON"
    >
      <div className={styles.informationContainer}>
        <Illustration
          illustrationSrc={HubIllustrationConstants.CHANGE_SETTINGS}
          illustrationAltText={HubIllustrationAltText.CHANGE_SETTINGS}
        />
        <div>
          <h2>{hubContent.you_can_add_users}</h2>
          <p>{hubContent.you_can_generate_users}</p>
          <p>
            <TextLink to={ADD_SUPERVISED_USERS_LINK} target="_blank">
              {hubContent.supervised_users_help_text}
            </TextLink>
          </p>
        </div>
      </div>
    </HubModal>
  );
}

export default SupervisedAdminInformationModal;

SupervisedAdminInformationModal.propTypes = {
  hubContent: PropTypes.object.isRequired,
  userId: PropTypes.string,
  userRole: PropTypes.string
};
