import React from 'react';
import PropTypes from 'prop-types';

import styles from './HubModal.scss';
import Button, { buttonTypes } from '../Button/Button';

function HubModal({ children, isOpen, onClose, id, closeButtonId }) {
  return (
    <div data-testid={id} className={`${styles.outerModalContainer} ${isOpen ? styles.openModal : ''}`}>
      <div className={`${styles.innerModalContainer}`}>
        <div className={styles.closeButton}>
          <Button dataTestId={closeButtonId} text="Close" type={buttonTypes.CLOSE} onClick={onClose} />
        </div>
        <div>{children && React.cloneElement(children)}</div>
      </div>
    </div>
  );
}

export default HubModal;

HubModal.propTypes = {
  children: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  closeButtonId: PropTypes.string,
  id: PropTypes.string
};
