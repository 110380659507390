import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import styles from '../PlacementTest.scss';
import {
  formatDate,
  formatExtraTime,
  formatReminders
} from '../../../../components/PlacementTestOnBoardingWizard/utils/helpers';
import { OPT, OPT_YL } from '../../../../components/PlacementTestOnBoardingWizard/utils/constants';
import PlacementTestEditJoiningCode from './PlacementTestEditJoiningCode';
import { featureIsEnabled } from '../../../../globals/envSettings';

function PlacementTestPanelSummary({
  placementTestType,
  languageVariationCode,
  listeningAccentsCode,
  testStartDate,
  testEndDate,
  extraTimePerItem,
  sendEmailsToStudents,
  notificationReminders,
  showResultsToStudents,
  editPlacementTestActions,
  placementTestStatus,
  panelSessionName,
  placementTestsContent,
  studentsList = [],
  numberOfLicenses = 0,
  selectedLicenses = 0,
  newStudents = [],
  existingStudentsInOrg = [],
  placesForUnknownStudents = 0,
  classCode = '',
  placesRemainingInSession = 0
}) {
  const [lockedFields, setLockedFields] = useState({
    placemnetTestType: true,
    languageVariation: true,
    listeningAccents: true,
    startDate: true,
    endDate: true,
    extraTime: true,
    addStudents: true,
    addLicenses: true,
    emailToStudents: true,
    reminders: true,
    showResults: true
  });

  const lockedFieldsByStatus = {
    placementTestType: {
      UPCOMING: true,
      ACTIVE: true
    },
    languageVariation: {
      UPCOMING: false,
      ACTIVE: true
    },
    listeningAccents: {
      UPCOMING: false,
      ACTIVE: true
    },
    startDate: {
      UPCOMING: false,
      ACTIVE: true
    },
    endDate: {
      UPCOMING: false,
      ACTIVE: false
    },
    extraTime: {
      UPCOMING: false,
      ACTIVE: true
    },
    addStudents: {
      UPCOMING: false,
      ACTIVE: false
    },
    addLicenses: {
      UPCOMING: false,
      ACTIVE: false
    },
    emailToStudents: {
      UPCOMING: false,
      ACTIVE: false
    },
    reminders: {
      UPCOMING: false,
      ACTIVE: false
    },
    showResults: {
      UPCOMING: false,
      ACTIVE: false
    }
  };

  useEffect(() => {
    const lockedFieldsValuesForTest = {};

    Object.entries(lockedFieldsByStatus).forEach(([fieldName, lockedValues]) => {
      lockedFieldsValuesForTest[fieldName] = lockedValues[placementTestStatus];
    });

    setLockedFields(lockedFieldsValuesForTest);
  }, [placementTestStatus]);

  const formattedStartDate = formatDate(testStartDate);
  const formattedEndDate = formatDate(testEndDate);
  const formattedExtraTime = formatExtraTime(extraTimePerItem, languageVariationCode, placementTestsContent);
  const formattedReminders = formatReminders(notificationReminders, placementTestsContent);
  const lockedIcon = <SVGIcon glyph={GLYPHS.ICON_LOCK} className={styles.lockIcon} />;
  const arrowRightIcon = <SVGIcon glyph={GLYPHS.ICON_RIGHT_THICK} className={styles.arrowRightIcon} />;
  const sessionSize = newStudents.length + existingStudentsInOrg.length + placesForUnknownStudents;
  const placesRemaining = numberOfLicenses - selectedLicenses;

  const renderArrowIcon = field => !lockedFields[field] && arrowRightIcon;

  const renderLockedIcon = field => lockedFields[field] && lockedIcon;

  return (
    <div className="pad2">
      {placementTestStatus !== 'COMPLETED' && (
        <PlacementTestEditJoiningCode classCodeId={classCode} placesRemainingInSession={placesRemainingInSession} />
      )}
      <div className={styles.summaryTable}>
        <table>
          <tr onClick={editPlacementTestActions.onClickSessionNameHandler}>
            <td>{placementTestsContent.placement_test_summary_session_name}</td>
            <td>
              {panelSessionName} {arrowRightIcon}
            </td>
          </tr>
        </table>
        <table>
          <tr className={lockedFields.placementTestType ? styles.locked : ''}>
            <td>{placementTestsContent.placement_test_summary_test}</td>
            <td>
              {renderLockedIcon('placementTestType')}
              {placementTestType === OPT_YL ? placementTestsContent[OPT_YL] : placementTestsContent[OPT]}
            </td>
          </tr>
          {languageVariationCode && (
            <tr
              className={lockedFields.languageVariation ? styles.locked : ''}
              onClick={
                !lockedFields.languageVariation && editPlacementTestActions.onClickSessionLanguageVariationHandle
              }
            >
              <td>{placementTestsContent.placement_test_summary_use_of_english}</td>
              <td>
                {renderLockedIcon('languageVariation')}
                {placementTestsContent[languageVariationCode]}
                {renderArrowIcon('languageVariation')}
              </td>
            </tr>
          )}
          {listeningAccentsCode && (
            <tr
              className={lockedFields.listeningAccents ? styles.locked : ''}
              onClick={!lockedFields.listeningAccents && editPlacementTestActions.onClickSessionListeningAccentsHandle}
            >
              <td>{placementTestsContent.placement_test_summary_accents}</td>
              <td>
                {renderLockedIcon('listeningAccents')}
                {placementTestsContent[listeningAccentsCode]}
                {renderArrowIcon('listeningAccents')}
              </td>
            </tr>
          )}
        </table>
        <table>
          <tr
            className={lockedFields.startDate ? styles.locked : ''}
            onClick={!lockedFields.startDate && editPlacementTestActions.onClickSessionStartDateHandle}
          >
            <td>{placementTestsContent.placement_test_summary_start}</td>
            <td>
              {renderLockedIcon('startDate')}
              {formattedStartDate}
              {renderArrowIcon('startDate')}
            </td>
          </tr>
          <tr
            className={lockedFields.endDate ? styles.locked : ''}
            onClick={!lockedFields.endDate && editPlacementTestActions.onClickSessionEndDateHandle}
          >
            <td>{placementTestsContent.placement_test_summary_end}</td>
            <td>
              {renderLockedIcon('endDate')}
              {formattedEndDate}
              {renderArrowIcon('endDate')}
            </td>
          </tr>
          <tr
            className={lockedFields.extraTime ? styles.locked : ''}
            onClick={!lockedFields.extraTime && editPlacementTestActions.onClickSessionExtraTimeHandle}
          >
            <td>{placementTestsContent.placement_test_summary_extra_time}</td>
            <td>
              {renderLockedIcon('extraTime')}
              {formattedExtraTime}
              {renderArrowIcon('extraTime')}
            </td>
          </tr>
        </table>
        <table>
          <tr
            className={lockedFields.addStudents ? styles.locked : ''}
            onClick={!lockedFields.addStudents && editPlacementTestActions.onClickSessionAddStudentsHandle}
          >
            <td>
              {studentsList.length > 0
                ? placementTestsContent.placement_test_students_added_to_session
                : placementTestsContent.placement_test_summary_students}
            </td>
            <td>
              {renderLockedIcon('addStudents')}
              <div className={styles.studentsAndLicencesContainer}>
                <div>
                  {studentsList.length > 0 ? (
                    <p>
                      {`${sessionSize} (${placementTestsContent.placement_test_places_remaining ||
                        ''.replace('[placesRemaining]', placesRemaining)})`}
                    </p>
                  ) : (
                    <>
                      <p>{placementTestsContent.placement_test_no_students_in_session}</p>
                      <p className={styles.studentsAndLicencesTitle}>
                        {placementTestsContent.placement_test_add_students}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {renderArrowIcon('addStudents')}
            </td>
          </tr>
          <tr
            className={lockedFields.addLicenses ? styles.locked : ''}
            onClick={!lockedFields.addLicenses && editPlacementTestActions.onClickSessionAddLicensesHandle}
          >
            <td>{placementTestsContent.placement_test_summary_licences}</td>
            <td>
              {renderLockedIcon('addLicenses')}
              <div className={styles.studentsAndLicencesContainer}>
                <div>
                  <p className={styles.studentsAndLicencesTitle}>
                    {placementTestsContent.placement_test_no_licences_available}
                  </p>
                  <p>{placementTestsContent.placement_test_add_students_page_add_license}</p>
                </div>
              </div>
              {renderArrowIcon('addLicenses')}
            </td>
          </tr>
        </table>
        <table>
          <tr
            className={lockedFields.emailToStudents ? styles.locked : ''}
            onClick={!lockedFields.emailToStudents && editPlacementTestActions.onClickSessionSendEmailToStudentsHandle}
          >
            <td>{placementTestsContent.placement_test_summary_notifications}</td>
            <td>
              {renderLockedIcon('emailToStudents')}
              {sendEmailsToStudents
                ? placementTestsContent.placement_test_summary_show_results_on
                : placementTestsContent.placement_test_summary_show_results_off}
              {renderArrowIcon('emailToStudents')}
            </td>
          </tr>
          {sendEmailsToStudents && formattedReminders.length > 0 && (
            <tr
              className={lockedFields.reminders ? styles.locked : ''}
              onClick={!lockedFields.reminders && editPlacementTestActions.onClickSessionRemindersHandle}
            >
              <td>{placementTestsContent.placement_test_summary_reminders}</td>
              <td>
                {renderLockedIcon('reminders')}
                {formattedReminders.map((item, index) => (
                  <span key={index}>{item}</span>
                ))}
                {renderArrowIcon('reminders')}
              </td>
            </tr>
          )}
          {featureIsEnabled('show-test-results-to-students') && (
            <tr
              className={lockedFields.showResults ? styles.locked : ''}
              onClick={!lockedFields.showResults && editPlacementTestActions.onClickSessionShowResultsHandle}
            >
              <td>{placementTestsContent.placement_test_summary_show_results}</td>
              <td>
                {renderLockedIcon('showResults')}
                {showResultsToStudents
                  ? placementTestsContent.placement_test_summary_show_results_on
                  : placementTestsContent.placement_test_summary_show_results_off}
                {renderArrowIcon('showResults')}
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
}

PlacementTestPanelSummary.propTypes = {
  placementTestType: PropTypes.string,
  languageVariationCode: PropTypes.string,
  listeningAccentsCode: PropTypes.string,
  testStartDate: PropTypes.string,
  testEndDate: PropTypes.string,
  extraTimePerItem: PropTypes.number,
  sendEmailsToStudents: PropTypes.bool,
  notificationReminders: PropTypes.array,
  showResultsToStudents: PropTypes.bool,
  editPlacementTestActions: PropTypes.shape({
    onClickSessionNameHandler: PropTypes.func,
    onClickSessionLanguageVariationHandle: PropTypes.func,
    onClickSessionListeningAccentsHandle: PropTypes.func,
    onClickSessionStartDateHandle: PropTypes.func,
    onClickSessionEndDateHandle: PropTypes.func,
    onClickSessionExtraTimeHandle: PropTypes.func,
    onClickSessionAddStudentsHandle: PropTypes.func,
    onClickSessionAddLicensesHandle: PropTypes.func,
    onClickSessionSendEmailToStudentsHandle: PropTypes.func,
    onClickSessionRemindersHandle: PropTypes.func,
    onClickSessionShowResultsHandle: PropTypes.func
  }),
  placementTestStatus: PropTypes.string,
  panelSessionName: PropTypes.string,
  placementTestsContent: PropTypes.object.isRequired,
  studentsList: PropTypes.array,
  numberOfLicenses: PropTypes.number,
  selectedLicenses: PropTypes.number,
  newStudents: PropTypes.array,
  existingStudentsInOrg: PropTypes.array,
  placesForUnknownStudents: PropTypes.number,
  classCode: PropTypes.string,
  placesRemainingInSession: PropTypes.number
};

export default compose(
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestType: placementTestSessionCreate.placementTest.placementTestType,
      languageVariationCode: placementTestSessionCreate.placementTest.languageVariationCode,
      listeningAccentsCode: placementTestSessionCreate.placementTest.listeningAccentsCode,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      sendEmailsToStudents: placementTestSessionCreate.placementTest.sendEmailsToStudents,
      notificationReminders: placementTestSessionCreate.placementTest.notificationReminders,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents,
      placementTestStatus: placementTestSessionCreate.placementTest.status,
      studentsList: placementTestSessionCreate.placementTest.studentsList,
      numberOfLicenses: placementTestSessionCreate.numberOfLicenses,
      selectedLicenses: placementTestSessionCreate.placementTest.selectedLicenses,
      newStudents: placementTestSessionCreate.placementTest.studentsList,
      existingStudentsInOrg: placementTestSessionCreate.placementTest.existingStudentsInOrg,
      placesForUnknownStudents: placementTestSessionCreate.placementTest.placesForUnknownStudents
    }),
    {}
  )
)(PlacementTestPanelSummary);
