import React from 'react';
import PropTypes from 'prop-types';
import SelfRegisteredUserOnboardingChooseRoleWizard from './SelfRegisteredUserOnboardingChooseRoleWizard';

function SelfRegisteredUserOnboardingWizard({ isOpen, firstName, selectRole, selfSelectRoleModalOpen }) {
  return (
    <div>
      <SelfRegisteredUserOnboardingChooseRoleWizard
        isOpen={isOpen}
        firstName={firstName}
        selectRole={selectRole}
        selfSelectRoleModalOpen={selfSelectRoleModalOpen}
      />
    </div>
  );
}

SelfRegisteredUserOnboardingWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired
};

export default SelfRegisteredUserOnboardingWizard;
