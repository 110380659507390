import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { HubLayoutConstants } from '../../../../../../globals/hubConstants';
// Component imports
import PanelHeading from '../../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../../components/ScrollContainer/ScrollContainer.js';
import PanelNavigationLink from '../../../../../../components/PanelNavigationLink/PanelNavigationLink.js';
import PopoutNavFooter from '../../../../../../components/PopoutNavFooter/PopoutNavFooter.js';

import {
  createLMSConfigRequest,
  toggleOxfordLTIConfig,
  setFormFieldData,
  validateFormFieldData,
  checkIfFormIsValid,
  getLMSOrgConfig,
  toggleEditOrgConfig
} from '../../../../../../redux/actions/lmsConfig.js';

import Validation from '../../../../../../components/Validation/Validation.js';
import TextInput from '../../../../../../components/TextInput/TextInput.js';
import CopyToClipboard from '../../../../../../components/CopyToClipboard/CopyToClipboard.js';
import SVGIcon, { GLYPHS } from '../../../../../../components/SVGIcon/SVGIcon';
import styles from './DeploymentTool.scss';
import { context } from '../../../../../../redux/reducers/hub/hubLMSConfig.reducer';
import { getPlatformBaseUrl, isLocal } from '../../../../../../globals/envSettings';

function OxfordLTIConfig({ isVisible, toggleAction }) {
  const ltiDomain = isLocal()
    ? 'http://localhost:7800/edu/open/ltitool1v3'
    : `${getPlatformBaseUrl('ngi')}/api/edu/open/ltitool1v3`;

  const ltiDomainUrl = isLocal() ? `http://localhost:7800/` : `${getPlatformBaseUrl('ngi')}`;

  const oxfordLTIConfigFields = {
    domain_url: { label: 'Domain URL', value: `${ltiDomainUrl}`, multiline: true },
    tool_url: { label: 'Tool URL', value: ltiDomain, multiline: true },
    oidc_url: {
      label: 'Initiate login URL',
      value: `${ltiDomain}/login/initiate`,
      multiline: true
    },
    oidc_redirect_url: {
      label: 'Redirection URI(s)',
      value: `${ltiDomain}/content/launch\r\n${ltiDomain}/content/selection`,
      multiline: true
    },
    public_key_url: {
      label: 'Public keyset URL (JWKS)',
      value: `${ltiDomain}/jwks`,
      multiline: true
    },
    content_selection_url: {
      label: 'Content Selection URL',
      value: `${ltiDomain}/content/selection`,
      multiline: true
    }
  };

  return (
    <div className={styles.oxfordLTIConfig}>
      <button
        type="button"
        className={styles.toggleButton}
        onClick={e => {
          e.preventDefault();
          toggleAction();
        }}
      >
        Show Oxford English Hub's Tool details{' '}
        <SVGIcon
          className={styles.icon}
          fill="currentColor"
          glyph={isVisible ? GLYPHS.CHEVRON_UP : GLYPHS.CHEVRON_DOWN}
        />
      </button>

      {isVisible && (
        <div>
          {Object.entries(oxfordLTIConfigFields).map(([key, { label, value, multiline }]) => (
            <CopyToClipboard
              key={key}
              id={key}
              label={label}
              value={value}
              buttonTitle={<SVGIcon glyph={GLYPHS.ICON_COPY} />}
              multiline={multiline}
            />
          ))}
        </div>
      )}
    </div>
  );
}

OxfordLTIConfig.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleAction: PropTypes.func.isRequired
};

function HowToMakeLTIIntegration({ hubContent }) {
  const { HOW_TO_ADD_AN_LTI_URL } = HubLayoutConstants;

  return (
    <div className={styles.howToBanner}>
      <span>{hubContent.tools_page_how_to_add_an_lti_tool_body}</span>
      <br />
      <a href={HOW_TO_ADD_AN_LTI_URL} target="_blank" rel="noreferrer">
        {hubContent.tools_page_how_to_add_an_lti_tool_title}
        <SVGIcon className={styles.icon} fill="currentColor" glyph={GLYPHS.ICON_LINK} />
      </a>
    </div>
  );
}

HowToMakeLTIIntegration.propTypes = {
  hubContent: PropTypes.object.isRequired
};

function EditSharedValuesButton({ action }) {
  return (
    <button type="button" className={styles.editOrgConfig} onClick={() => action()}>
      Edit shared values
    </button>
  );
}

EditSharedValuesButton.propTypes = {
  action: PropTypes.func.isRequired
};

export function Form({
  closePanel,
  submitFormAction,
  toggleOxfordLTIConfigAction,
  setFormFieldDataAction,
  validateFormFieldDataAction,
  checkIfFormIsValidAction,
  orgId,
  orgName,
  state,
  hubContent,
  hasTools,
  mode,
  orgConfig,
  orgConfigLoading,
  getLMSOrgConfigAction,
  toggleEditOrgConfigAction
}) {
  // -- prepopulate state with existing lmsOrgConfig
  React.useEffect(() => {
    if (orgConfigLoading) return;
    if (!hasTools || mode === context.EDIT) return;

    getLMSOrgConfigAction({ ...omit(orgConfig, 'tool') });
  }, []);

  const { HOW_TO_ADD_AN_LTI_URL } = HubLayoutConstants;
  const { isFormValid, formData: form, invalidFields } = state;
  const isAddMode = mode === context.ADD;
  const isEditMode = mode === context.EDIT;
  const isEditConfig = mode === context.EDIT_CONFIG;

  const customLTIConfigFields = {
    iss: { label: 'Issuer / Platform ID', placeholder: 'eg. https://mylms.com', config: true, info: '' },
    authenticationRequestUrl: {
      label: 'Authentication request URL',
      placeholder: 'eg https://mylms.com/mod/lti/auth.php',
      config: true,
      info: ''
    },
    publicKeysetUrl: {
      label: 'Public keyset URL (JWKS)',
      placeholder: 'eg https://mylms.com/mod/lti/certs.php or https://mylms.com/lti/jwks',
      config: true,
      info: ''
    },
    accessTokenUrl: {
      label: 'Access token URL',
      placeholder: 'eg https://mylms.com/lti/mod/lti/token.php',
      config: true,
      info: ''
    },
    displayName: { label: 'Display name', placeholder: 'eg My Tool', config: false, info: '' },
    deploymentId: {
      label: 'Deployment ID',
      placeholder: 'eg 2',
      config: false,
      readonly: true,
      info: 'Deployment ID cannot be edited'
    },
    clientId: {
      label: 'Client ID',
      placeholder: 'eg 1',
      config: false,
      readonly: true,
      info: 'A Client ID cannot be changed once it has been set'
    }
  };

  const ui = {
    ADD: {
      title: hasTools ? hubContent.tools_page_form_deployment_add_title : hubContent.tools_page_form_add_title,
      subtitle: hasTools
        ? hubContent.tools_page_form_deployment_add_subtitle.replace('{orgName}', orgName)
        : hubContent.tools_page_form_add_subtitle.replace('{orgName}', orgName),
      button: hasTools ? hubContent.add_deployment : hubContent.add_integration,
      cancelBtn: 'Cancel',
      action: () => submitFormAction(orgId, '', form)
    },
    EDIT: {
      title: hasTools ? hubContent.tools_page_form_deployment_edit_title : hubContent.tools_page_form_edit_title,
      subtitle: hubContent.tools_page_form_edit_subtitle.replace('{displayName}', form.displayName),
      button: 'Save',
      cancelBtn: 'Cancel',
      action: () => submitFormAction(orgId, form.toolId, form)
    },
    EDIT_CONFIG: {
      title: hubContent.tools_page_form_edit_config_title,
      subtitle: hubContent.tools_page_form_edit_config_subtitle,
      button: 'Save',
      cancelBtn: 'Back',
      action: () => submitFormAction(orgId, form.toolId, form),
      backAction: () => toggleEditOrgConfigAction(form.toolId ? context.EDIT : context.ADD)
    }
  }[mode];

  const UiHeader = () =>
    ({
      ADD: (
        <>
          <HowToMakeLTIIntegration hubContent={hubContent} />
          {!hasTools && (
            <OxfordLTIConfig isVisible={state.oxfordLTIConfig} toggleAction={toggleOxfordLTIConfigAction} />
          )}
        </>
      ),
      EDIT: (
        <>
          <HowToMakeLTIIntegration hubContent={hubContent} />
          <OxfordLTIConfig isVisible={state.oxfordLTIConfig} toggleAction={toggleOxfordLTIConfigAction} />
        </>
      ),
      EDIT_CONFIG: (
        <>
          <p>These settings are shared between all the deployments in this Organisation</p>
          <p>
            Need help with deployment settings?&nbsp;
            <a href={HOW_TO_ADD_AN_LTI_URL} target="_blank" rel="noreferrer">
              {hubContent.tools_page_how_to_add_an_lti_tool_title}
              <SVGIcon className={styles.icon} fill="currentColor" glyph={GLYPHS.ICON_LINK} />
            </a>
          </p>
        </>
      )
    }[mode]);

  return (
    <ScrollContainer
      headerContent={
        <div>
          <div className="text-right">
            <PanelNavigationLink isLhs={false} text={hubContent.close_panel_text} action={closePanel} />
          </div>
          <PanelHeading title={ui.title} subtitle={ui.subtitle} />
        </div>
      }
      footerContent={
        <PopoutNavFooter
          nextButtonText={ui.button}
          nextAction={ui.action}
          nextButtonDisabled={!isFormValid}
          backButtonText={ui.cancelBtn}
          backAction={ui.backAction || closePanel}
        />
      }
    >
      <div className={styles.addDeploymentTool}>
        <UiHeader />

        {Object.entries(customLTIConfigFields)
          // eslint-disable-next-line no-unused-vars
          .filter(([key, value]) => (hasTools ? value.config === isEditConfig && !value.shared : true))
          .map(([key, { label, placeholder, readonly }]) => (
            <Validation
              key={key}
              forId={key}
              name={key}
              isValid={!!form[key] && !invalidFields.includes(key)}
              isError={invalidFields.includes(key)}
              noIcon={isEditMode && readonly}
              message={
                // eslint-disable-next-line no-nested-ternary
                invalidFields.includes(key)
                  ? form[key]
                    ? hubContent.tools_page_validation_invalid_input
                    : hubContent.tools_page_validation_empty_input
                  : null
              }
            >
              <TextInput
                id={key}
                name={key}
                placeholder={placeholder}
                label={label}
                value={form[key] || ''}
                disabled={isEditMode && readonly}
                onChange={input => {
                  setFormFieldDataAction(key, input);
                  validateFormFieldDataAction(key);
                  checkIfFormIsValidAction(customLTIConfigFields);
                }}
              />
            </Validation>
          ))}

        {hasTools && isAddMode && <EditSharedValuesButton action={toggleEditOrgConfigAction} />}

        {isEditMode && (
          <>
            <div className={styles.orgConfigFields}>
              {Object.entries(customLTIConfigFields)
                // eslint-disable-next-line no-unused-vars
                .filter(([key, value]) => value.config === true && !value.shared)
                .map(([key, { label, placeholder, info }]) => (
                  <Validation key={key} id={key} isValid noIcon forId={key}>
                    <>
                      <TextInput
                        id={key}
                        placeholder={placeholder}
                        name={`${key}_field`}
                        label={label}
                        value={form[key]}
                        disabled
                      />
                      {info && <span className={styles.fieldInfo}>{info}</span>}
                    </>
                  </Validation>
                ))}

              <EditSharedValuesButton action={toggleEditOrgConfigAction} />
            </div>

            {Object.entries(customLTIConfigFields)
              // eslint-disable-next-line no-unused-vars
              .filter(([key, value]) => value.shared)
              .map(([key, { label, placeholder, info }]) => (
                <Validation key={key} id={key} isValid noIcon forId={key}>
                  <>
                    <TextInput id={key} placeholder={placeholder} name={key} label={label} value={form[key]} disabled />
                    {info && <span className={styles.fieldInfo}>{info}</span>}
                  </>
                </Validation>
              ))}
          </>
        )}
      </div>
    </ScrollContainer>
  );
}

Form.propTypes = {
  closePanel: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  toggleOxfordLTIConfigAction: PropTypes.func.isRequired,
  setFormFieldDataAction: PropTypes.func.isRequired,
  validateFormFieldDataAction: PropTypes.func.isRequired,
  checkIfFormIsValidAction: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  hubContent: PropTypes.object.isRequired,
  hasTools: PropTypes.bool.isRequired,
  orgConfig: PropTypes.object.isRequired,
  orgConfigLoading: PropTypes.bool.isRequired,
  getLMSOrgConfigAction: PropTypes.func.isRequired,
  toggleEditOrgConfigAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    state: state.createLMSConfig,
    orgId: state.identity.currentOrganisationId,
    userId: state.identity.userId,
    orgConfig: state.search.lmsConfig.data[0],
    orgConfigLoading: state.search.lmsConfig.loading
  }),
  {
    submitFormAction: createLMSConfigRequest,
    getLMSOrgConfigAction: getLMSOrgConfig,
    toggleOxfordLTIConfigAction: toggleOxfordLTIConfig,
    setFormFieldDataAction: setFormFieldData,
    validateFormFieldDataAction: validateFormFieldData,
    checkIfFormIsValidAction: checkIfFormIsValid,
    toggleEditOrgConfigAction: toggleEditOrgConfig
  }
)(Form);
