import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import PlacementTestStartEndDate from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestSchedule/PlacementTestStartEndDate';
import PlacementTestExtraTime from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestSchedule/PlacementTestExtraTime';

import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import { setEditedPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';

import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestEditTestSchedule({
  panelSessionName,
  updatePlacementTestSessionRequestAction,
  cancelTestSchedulePanel,
  orgId,
  placementTestSessionId,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent },
  testStartDate,
  testEndDate,
  extraTimePerItem,
  testStartDateIsInvalid,
  testEndDateIsInvalid,
  setEditedPlacementTestAction
}) {
  const [initialState, setInitialState] = useState(null);
  const [isTestScheduleTouched, setIsTestScheduleTouched] = useState(false);

  useEffect(() => {
    setInitialState({
      initialTestStartDate: testStartDate,
      initialTestEndDate: testEndDate,
      initialExtraTimePerItem: extraTimePerItem
    });
  }, []);

  useEffect(() => {
    if (
      initialState &&
      (testStartDate !== initialState?.initialTestStartDate ||
        testEndDate !== initialState?.initialTestEndDate ||
        extraTimePerItem !== initialState?.initialExtraTimePerItem)
    ) {
      setIsTestScheduleTouched(true);
    } else {
      setIsTestScheduleTouched(false);
    }
  }, [testStartDate, testEndDate, extraTimePerItem]);

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_session_manage_test_schedule}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <Footer
          primaryButtonLabel={hubContent.save}
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              sessionId: placementTestSessionId,
              placementTest: {
                testStartDate,
                testEndDate,
                extraTimePerItem
              }
            });
            setEditedPlacementTestAction({ testStartDate, testEndDate, extraTimePerItem });
            cancelTestSchedulePanel(e, true);
          }}
          secondaryButtonLabel={hubContent.cancel}
          secondaryButtonAction={cancelTestSchedulePanel}
          primaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_EDIT_TEST_SCHEDULE_SAVE_BUTTON'
            },
            disabled: !isTestScheduleTouched || !!testStartDateIsInvalid || !!testEndDateIsInvalid
          }}
          secondaryButtonAttributes={{
            dataAttributes: {
              testid: 'PLACEMENT_TEST_PANEL_EDIT_TEST_SCHEDULE_CANCEL_BUTTON'
            }
          }}
        />
      }
    >
      <div className="pad2">
        <PlacementTestStartEndDate />
        <PlacementTestExtraTime />
      </div>
    </ScrollContainer>
  );
}

PlacementTestEditTestSchedule.propTypes = {
  panelSessionName: PropTypes.string.isRequired,

  testStartDate: PropTypes.string,
  testEndDate: PropTypes.string,
  extraTimePerItem: PropTypes.number,
  testStartDateIsInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  testEndDateIsInvalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setEditedPlacementTestAction: PropTypes.func,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  cancelTestSchedulePanel: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => ({
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      testStartDateIsInvalid: placementTestSessionCreate.testStartDateIsInvalid,
      testEndDateIsInvalid: placementTestSessionCreate.testEndDateIsInvalid,
      placementTest: placementTestSessionCreate.placementTest,
      orgId: identity.currentOrganisationId,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId
    }),
    {
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestEditTestSchedule);
