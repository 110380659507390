import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import Link from '../Link/Link';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../Button/Button';
import withLocalizedContent from '../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingOrganizationSetupWizardHeader({
  secondWizardType,
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    hubGlossary: hubContent
  },
  setIsWarningModalOpen,
  setIsOpenSelfRegisteredUserModal
}) {
  const { step } = useWizard();
  const currentStep = step + 1;

  const renderWizardTitle = () => {
    switch (currentStep) {
      case 1:
        return secondWizardType === 'class'
          ? selfRegisteredUserOnboardingWizardContent.staff_role_chooses_classes_and_courses_wizard_title
          : selfRegisteredUserOnboardingWizardContent.staff_role_chooses_placement_test_wizard_title;
      default:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_create_organization;
    }
  };

  const renderTextForStep = () => {
    switch (currentStep) {
      case 1:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_welcome;
      case 2:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_about_you;
      case 3:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_invite_staff;
      case 4:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_success;
      default:
        return '';
    }
  };

  const handleClose = () => (currentStep === 4 ? setIsOpenSelfRegisteredUserModal(true) : setIsWarningModalOpen(true));

  return (
    <div className={wizardStyles.header}>
      <div className={wizardStyles.headerContainer}>
        <div className={wizardStyles.title}>
          <span>{renderWizardTitle()}</span> {renderTextForStep()}
        </div>
        <div className={wizardStyles.helpAndSupport}>
          <Link
            dataTestId="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUPPORT_BUTTON"
            to={hubContent.help_and_support_link_to}
            target="_blank"
            role="button"
          >
            <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
            <span className={wizardStyles.helpSupportText}>{hubContent.help_and_support}</span>
            <span className="a11y-hide">Help</span>
          </Link>
          {currentStep > 0 && (
            <Button
              text="Close"
              onClick={handleClose}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              customClassName={wizardStyles.flexRight}
            />
          )}
        </div>
      </div>
    </div>
  );
}

SelfRegisteredUserOnboardingOrganizationSetupWizardHeader.propTypes = {
  secondWizardType: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  setIsWarningModalOpen: PropTypes.func.isRequired,
  setIsOpenSelfRegisteredUserModal: PropTypes.func.isRequired
};

export default withLocalizedContent(
  'selfRegisteredUserOnboardingWizard',
  'hubGlossary'
)(SelfRegisteredUserOnboardingOrganizationSetupWizardHeader);
