import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';
// Components
import PageHero from '@oup/shared-front-end/src/components/PageHero';
import SecondaryNavigation from '@oup/shared-front-end/src/components/SecondaryNavigation';
import Button from '@oup/shared-front-end/src/components/Button';
import styles from './HubPageLayout.scss';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

function HubPageLayout({
  sideNavOpen,
  pageHero,
  secondaryNavigation,
  stickyButton,
  allowContentFullWidth,
  children,
  stickyContainerConfig = null
}) {
  const contentEl = useRef(null);
  const navEl = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = debounce(() => {
    if (contentEl.current && navEl.current) {
      const contentTop = contentEl.current.getBoundingClientRect().top;
      const headerHeight = document.getElementById('fixedHeader')?.getBoundingClientRect().height || 75;
      const navHeight = navEl.current.getBoundingClientRect().height;
      const scroll = contentTop - headerHeight - navHeight;
      setScrollPosition(scroll);
    }
  }, 0);

  // Handle scroll event to set secondary nav to fixed position
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (scrollPosition !== 0) {
      setScrollPosition(0);
    }
  }, [children]);

  return (
    <div
      className={classNames(
        styles.hubPageLayout,
        { [styles.navFixed]: scrollPosition < 0 },
        { [styles.sideNavOpen]: sideNavOpen }
      )}
    >
      <div className={styles.pageHeroContainer}>
        <PageHero {...pageHero} />
      </div>
      {stickyButton && (
        <div
          className={`${styles.stickyContainer} , ${
            stickyContainerConfig?.className ? styles[stickyContainerConfig.className] : ''
          }`}
          ref={navEl}
        >
          {stickyContainerConfig?.title && <span>{stickyContainerConfig.title}</span>}
          <Button
            onClick={stickyButton?.onClick}
            text={stickyButton.text}
            variant="filled"
            dataAttributes={{ testid: stickyButton.actionTestHook }}
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
          />
        </div>
      )}
      {secondaryNavigation && Object.keys(secondaryNavigation).length > 0 && (
        <div className={styles.secondaryNavigationContainer} ref={navEl}>
          <SecondaryNavigation {...secondaryNavigation} />
        </div>
      )}
      <div
        className={classNames(styles.pageContent, { [styles.allowContentFullWidth]: allowContentFullWidth })}
        ref={contentEl}
      >
        {children}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  sideNavOpen: state.hubUi.sideNavOpen
});

HubPageLayout.propTypes = {
  sideNavOpen: PropTypes.bool,
  children: PropTypes.any,
  secondaryNavigation: PropTypes.shape({
    ...SecondaryNavigation.propTypes
  }),
  pageHero: PropTypes.shape({
    ...PageHero.propTypes
  }).isRequired,
  stickyButton: PropTypes.shape({
    ...Button.propTypes,
    text: PropTypes.string,
    onClick: PropTypes.func,
    actionTestHook: PropTypes.string
  }),
  allowContentFullWidth: PropTypes.bool,
  stickyContainerConfig: PropTypes.object
};

export default connect(mapStateToProps)(HubPageLayout);
