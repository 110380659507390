import React, { useEffect } from 'react';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { ICON_LEFT } from '@oup/shared-front-end/src/svg/oup';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import { connect } from 'react-redux';
import withLocalizedContent from '../../language/withLocalizedContent';
import actions from '../../redux/actions';
import { getCurrentPlatform } from '../../utils/platform';

function SelfRegisteredUserOnboardingOrgSetupWizardFooter({
  localizedContent: { hubGlossary: hubContent },
  branch,
  register,
  formData,
  errors,
  submitting,
  success,
  setIsOpenSelfRegisteredUserModal,
  showSelfSelectRoleModalAction
}) {
  const { isLoading, isFirstStep, nextStep, prevStep, isPrimaryButtonDisabled, isLastStep } = useWizard();

  const setPrimaryButtonLabel = () => {
    let btnLabel;
    if (isLastStep) btnLabel = hubContent.done;
    else if (submitting) btnLabel = '';
    else btnLabel = hubContent.next;
    return btnLabel;
  };

  const getCreateOrgBranch = () =>
    branch === ORG_REGISTRATION_CONTEXT.PLACEMENT ? branch : ORG_REGISTRATION_CONTEXT.CES;

  const getCreateOrgPayload = () => {
    const { name, ...restFormData } = formData;
    const payload = { ...restFormData, name, platformCode: getCurrentPlatform(), isLmsLtiTool: false };

    if (getCreateOrgBranch() === ORG_REGISTRATION_CONTEXT.PLACEMENT) {
      payload.placementCenter = true;
    }

    return payload;
  };

  const isNextDisabled =
    submitting || isLoading || !Object.values(errors).length || Object.values(errors).some(val => val === true);

  const handlePrimaryStep = () => {
    switch (true) {
      case isFirstStep:
        register(getCreateOrgPayload(), getCreateOrgBranch());
        break;
      case isLastStep:
        setIsOpenSelfRegisteredUserModal(true);
        showSelfSelectRoleModalAction(false);
        break;
      default:
        nextStep();
        break;
    }
  };

  useEffect(() => {
    if (isFirstStep && success && !submitting) nextStep();
  }, [isFirstStep, success, submitting]);

  return (
    <Footer
      primaryButtonAction={handlePrimaryStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: isFirstStep ? isNextDisabled : isPrimaryButtonDisabled,
        loading: !!submitting,
        dataAttributes: {
          testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON'
        }
      }}
      secondaryButtonAction={isLastStep ? prevStep : null}
      secondaryButtonLabel={isLastStep ? hubContent.button_back_text : null}
      secondaryButtonAttributes={{
        icon: {
          component: <ICON_LEFT />
        }
      }}
    />
  );
}

SelfRegisteredUserOnboardingOrgSetupWizardFooter.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  branch: PropTypes.string,
  register: PropTypes.func,
  formData: PropTypes.object,
  errors: PropTypes.object,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  setIsOpenSelfRegisteredUserModal: PropTypes.func,
  showSelfSelectRoleModalAction: PropTypes.func
};

export default compose(
  withLocalizedContent('hubGlossary'),
  connect(
    state => ({
      ...pick(state.orgRegistration, ['errors', 'submitting', 'success', 'failure'])
    }),
    dispatch => ({
      register: (input, branch) => {
        dispatch(actions.registerOrgRequest(input, branch));
      }
    })
  )
)(SelfRegisteredUserOnboardingOrgSetupWizardFooter);
