import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HubListHeader from './HubListHeader';
import { filterItems } from '../HubDashboardLayout/Services/filterPeople';
import styles from './withHubList.scss';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import userRoles from '../../globals/userRoles';
import calcDropdownPosition from '../HubOrganizationLayout/Utils/calcDropdownPosition';

const withHubList = Component => {
  function EmptyStateDataComponent(title, subtitle, button, link, iconSrc, iconAlt, buttonAdd) {
    return (
      <HubEmptyStateRestyle
        iconSrc={iconSrc}
        iconAlt={iconAlt}
        title={title}
        bodyText={subtitle}
        btnFilledBase={button}
        link={link}
        btnOutlineBase={buttonAdd}
      />
    );
  }
  function WithHubList({
    lists = [],
    headerItems = [],
    currentUsersList = {},
    emptyStateData: {
      title = '',
      subtitle = '',
      iconSrc = '',
      iconAlt = '',
      links = {},
      button = {},
      buttonAdd = {}
    } = {},
    totalResults,
    currentOrganisationLti,
    hasHeaderButtonAccess,
    ...props
  }) {
    return (
      <div>
        {totalResults
          ? lists.map((itemIds, index) => {
              const itemsWithCheckbox = filterItems(itemIds, currentUsersList);
              const headerButtonAccess =
                itemIds.length &&
                currentUsersList[itemIds[0]].roleName !== userRoles.ORG_ADMIN &&
                currentOrganisationLti
                  ? false
                  : hasHeaderButtonAccess;
              return (
                <div key={index}>
                  {itemIds.length ? (
                    <div className={classnames(headerItems[index] ? { [styles.listBorder]: index !== 0 } : {})}>
                      <HubListHeader
                        itemIds={itemsWithCheckbox}
                        item={headerItems[index]}
                        {...props}
                        hasHeaderButtonAccess={headerButtonAccess}
                      />
                      <ul>
                        {itemIds.map((id, itemIndex) => (
                          <Component
                            key={id}
                            id={id}
                            itemIds={itemsWithCheckbox}
                            tabindex={itemIndex}
                            dropdownTop={calcDropdownPosition(itemIds, itemIndex)}
                            currentUsersList={currentUsersList}
                            currentOrganisationLti={currentOrganisationLti}
                            {...props}
                          />
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
              );
            })
          : EmptyStateDataComponent(title, subtitle, button, links, iconSrc, iconAlt, buttonAdd)}
      </div>
    );
  }
  WithHubList.propTypes = {
    lists: PropTypes.array,
    headerItems: PropTypes.array,
    currentUsersList: PropTypes.object,
    emptyStateData: PropTypes.object,
    totalResults: PropTypes.number.isRequired,
    currentOrganisationLti: PropTypes.bool.isRequired,
    hasHeaderButtonAccess: PropTypes.bool
  };

  return WithHubList;
};

export default withHubList;
