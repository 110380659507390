import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setTestEndDate, setTestStartDate } from '../../../redux/reducers/placementTestSessionCreate';
import OnlineTestDateTime from '../../OnlineTestDateTime/OnlineTestDateTime';

function PlacementTestStartEndDate({
  setTestStartDateAction,
  setTestEndDateAction,
  defaultTestStartDate,
  defaultTestEndDate
}) {
  return (
    <OnlineTestDateTime
      showTooltipEndDateTime={false}
      onSelectStartDateTime={(startDate, hasStartDateErrors) => {
        setTestStartDateAction(startDate.toISOString(), hasStartDateErrors);
      }}
      onSelectEndDateTime={(endDate, hasEndDateErrors) => {
        setTestEndDateAction(endDate.toISOString(), hasEndDateErrors);
      }}
      defaultStartDateTime={defaultTestStartDate ? new Date(defaultTestStartDate) : null}
      defaultEndDateTime={defaultTestEndDate ? new Date(defaultTestEndDate) : null}
    />
  );
}

PlacementTestStartEndDate.propTypes = {
  setTestStartDateAction: PropTypes.func,
  setTestEndDateAction: PropTypes.func,
  defaultTestStartDate: PropTypes.string,
  defaultTestEndDate: PropTypes.string
};

export default compose(
  connect(
    ({ placementTestSessionCreate }) => ({
      defaultTestStartDate: placementTestSessionCreate.placementTest.testStartDate,
      defaultTestEndDate: placementTestSessionCreate.placementTest.testEndDate
    }),
    {
      setTestStartDateAction: setTestStartDate,
      setTestEndDateAction: setTestEndDate
    }
  )
)(PlacementTestStartEndDate);
