import classnames from 'classnames';
import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import { parse as parseQueryString } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// Components
import AccountButton from '@oup/shared-front-end/src/components/AccountButton/AccountButton.js';
import Link from '../../components/Link/Link';
import Button, { buttonTypes } from '../../components/Button/Button.js';
import ImpersonationAccountButton from '../../components/ImpersonationAccountButton/ImpersonationAccountButton.js';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import AccountMenu from '../../components/AccountMenu/AccountMenu';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';

import USER_ROLES, {
  roleIsAtLeast,
  userRoleFriendlyNames,
  roleCanCreateSecondaryOrg
} from '../../globals/userRoles.js';
import { switchOrg, triggerLogout } from '../../redux/reducers/identity.reducer.js';
import { getLogoutUrl } from '../../redux/sagas/appFunctions/authorisedFunctions/auth/handleLogout.saga.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { isPublicPath } from '../../globals/authSettings';

import { socialIdpIsEnabled } from '../../globals/envSettings';
import { getCurrentPlatform, isRvsMode } from '../../utils/platform.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { toggleAccountWidget } from '../../redux/reducers/app.reducer.js';
import { openEditMyAccountModal } from '../../redux/actions/hubUi';
import getCurrentOrganisation from '../../redux/selectors/getCurrentOrganisation';
import getCurrentUser from '../../redux/selectors/getCurrentUser';
import getCurrentUserOrganisations from '../../redux/selectors/getCurrentUserOrganisations';
import styles from './HubHeader.scss';
import isRegion from '../../utils/isRegion';
import localStorageWithCache from '../../utils/localStorageWithCache';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import { RvsLayoutConstants } from '../../globals/rvsConstants.js';
import breakpoints from '../../globals/breakpoints';

export class HubHeader extends Component {
  static currentPathname = window.location.pathname;

  static _emailSignIn = () => {
    window.location.href = HubLayoutConstants.PATH_NAMES.REGISTER_PATH;
  };

  static _handleRegister = async () => {
    if (socialIdpIsEnabled(getCurrentPlatform())) {
      window.location.href = HubLayoutConstants.PATH_NAMES.REGISTER_CHOICE_PATH;
    } else {
      window.location.href = HubLayoutConstants.PATH_NAMES.REGISTER_PATH;
    }
  };

  componentDidMount() {
    const { linkSocialAccount, onLinkAccount } = this.props;

    if (linkSocialAccount) {
      onLinkAccount();
    }
  }

  _getLogoUrl = () => {
    const { userSignedIn } = this.props;
    const hubURL = userSignedIn
      ? HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH
      : HubLayoutConstants.PATH_NAMES.HOME_PAGE;
    const rvsURL = RvsLayoutConstants.PATH_NAMES.HOME_PAGE;
    return isRvsMode() ? rvsURL : hubURL;
  };

  _renderLogo = () => {
    const {
      localizedContent: { siteHeaders: content },
      darkLayout,
      breakpoint
    } = this.props;

    let logo = darkLayout ? GLYPHS.OUP_LOGO_BLUE_WHITE : GLYPHS.OUP_LOGO_BLUE;
    if (breakpoint === breakpoints.XXS) {
      logo = darkLayout ? GLYPHS.OUP_LOGO_BLUE_WORDMARK_WHITE : GLYPHS.OUP_LOGO_BLUE_WORDMARK;
    }
    return (
      <div>
        <SVGIcon glyph={logo} />
        <span className="a11y-hide">{content.homepage_text}</span>
      </div>
    );
  };

  _renderLogoLink = () => {
    const { disableLogoLink } = this.props;

    return disableLogoLink ? (
      this._renderLogo()
    ) : (
      <a id="navHeader-homeButton" className={styles.oupLogoContainer} href={this._getLogoUrl()}>
        {this._renderLogo()}
      </a>
    );
  };

  _renderBurgerMenuButton = () => {
    const {
      onToggleMenu,
      userSignedIn,
      userId,
      userRole,
      hubMenuSelected,
      selfSelectRoleModalOpen,
      hasPendingInvites,
      localizedContent: { siteHeaders: content }
    } = this.props;

    const { getKey } = localStorageWithCache;
    const userIdFromLs = get(JSON.parse(getKey('selectedRoleData')), ['userId'], '');
    const isResetPasswordView = HubHeader.currentPathname.includes(HubLayoutConstants.PATH_NAMES.RESET_PASSWORD);

    const hideBurgerMenuOnSelfSelect =
      !roleIsAtLeast(USER_ROLES.MANAGED_USER, userRole) && selfSelectRoleModalOpen && userIdFromLs !== userId;

    if (!userSignedIn || hideBurgerMenuOnSelfSelect || hasPendingInvites || isResetPasswordView) return null;
    const hubMenuButtonAriaLabel = `${content.toggle_navigation} ${hubMenuSelected ? 'expanded' : 'collapsed'}`;

    return (
      <li className={styles.menuButton}>
        <button
          id="navHeader-menuButton"
          data-testid="HUB_HEADER_MENU_BUTTON"
          onClick={onToggleMenu}
          className={classnames({ [styles.hubMenuSelected]: hubMenuSelected })}
          aria-label={hubMenuButtonAriaLabel}
          type="button"
        >
          <SVGIcon glyph={GLYPHS.ICON_MENU} />
          <span className="a11y-hide">{content.toggle_navigation}</span>
        </button>
      </li>
    );
  };

  _renderLanguageSelector = () => {
    const { hideLanguageSelector = true, darkLayout } = this.props;

    if (hideLanguageSelector) return null;

    return (
      <li className={styles.languageSelector}>
        <LanguageSelector buttonId="languageSelector" darkLayout={darkLayout} />
      </li>
    );
  };

  _renderHelpLink = () => {
    const {
      hideHelpLink,
      localizedContent: { siteHeaders: content }
    } = this.props;

    if (hideHelpLink) return null;

    let linkHref = HubLayoutConstants.HELP_AND_SUPPORT_URL;

    if (isRegion('kb')) {
      linkHref = 'https://support.kerboodle.com/';
    }

    if (isRvsMode()) {
      linkHref = '/support/index';
    }

    return (
      <li className={styles.helpButton}>
        <Link
          id="navHeader-supportButton"
          to={linkHref}
          openInNewTab
          target="_blank"
          role="button"
          dataTestId="GOTO_SUPPORT"
          aria-label={content.help_and_support}
        >
          <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
          <span className={styles.helpSupportText}>{content.help_and_support}</span>
        </Link>
      </li>
    );
  };

  _handleSignIn = async () => {
    const { platform } = this.props;
    const url = await getIdpLoginUrl(null, { platform, withReturnTo: !isPublicPath(HubHeader.currentPathname) });
    window.location.href = url;
  };

  _renderUnauthorizedUserButtons = () => {
    const {
      userSignedIn,
      hideRegisterLink,
      hideSignInLink,
      localizedContent: { siteHeaders: content }
    } = this.props;

    if (userSignedIn) return null;

    return (
      <li className={styles.loginButton}>
        {!hideRegisterLink ? (
          <Button
            id="register-button"
            customClassName={styles.registerButton}
            type={buttonTypes.GHOST_INVERTED}
            glyph={GLYPHS.ICON_USER_CIRCLE}
            disableExternalBehaviour
            onClick={HubHeader._handleRegister}
            text={content.registerNowButton_link_text}
            tabIndexPrevIconSvg={null}
            dataTestId="GOTO_REGISTER"
          />
        ) : null}
        {!hideSignInLink ? (
          <Button
            id="signIn"
            type={buttonTypes.GHOST_INVERTED}
            glyph={GLYPHS.ICON_USER_CIRCLE}
            text={content.sign_in_text}
            onClick={this._handleSignIn}
            ariaLabel={content.sign_in_with_oxford_id_text}
            disableExternalBehaviour
            tabIndexPrevIconSvg={null}
            dataTestId="GOTO_LOGIN"
          />
        ) : null}
      </li>
    );
  };

  _renderAccountWidget = () => {
    const {
      userSignedIn,
      hideWidgetDropDown,
      accountWidgetOpen,
      handleToggleAccountWidget,
      userFirstName,
      userLastName,
      orgId,
      orgConfig,
      canRegisterOrg,
      onOrgRegisterClick,
      onEditAccountClick,
      myOrgUrl,
      onOrgChange,
      orgChangeUrl
    } = this.props;

    if (!userSignedIn || hideWidgetDropDown) return null;

    const onOrgValueChanged = id => onOrgChange(id, orgChangeUrl);
    const isImpersonationMode = !!localStorage.getItem('impersonation-token');

    return (
      <li className={isImpersonationMode ? styles.impersonationAccountButton : styles.accountButton}>
        {isImpersonationMode ? (
          <ImpersonationAccountButton
            buttonElementId="navHeader-accountWidget"
            icon={GLYPHS.ICON_IMPERSONATION}
            onClick={handleToggleAccountWidget}
            ariaLabel="User account management widget"
            isOpen={accountWidgetOpen}
          />
        ) : (
          <AccountButton
            id="navHeader-accountWidget"
            ariaAttributes={{ label: 'User account management widget' }}
            onClick={handleToggleAccountWidget}
            firstName={userFirstName}
            lastName={userLastName}
            active={accountWidgetOpen}
          />
        )}

        {accountWidgetOpen && (
          <AccountMenu
            {...pick(this.props, [
              'logoutUrl',
              'userFirstName',
              'userLastName',
              'userName',
              'userEmail',
              'userRole',
              'friendlyUserRole',
              'userOrganisations',
              'orgName',
              'orgRole',
              'customId',
              'onLogout'
            ])}
            orgId={orgId}
            orgConfig={orgConfig}
            buttonElementId="navHeader-accountWidget"
            onTogglePopup={handleToggleAccountWidget}
            onOrgRegisterClick={canRegisterOrg ? () => onOrgRegisterClick() : undefined}
            onEditAccountClick={onEditAccountClick}
            myOrgUrl={myOrgUrl}
            onOrgChange={onOrgValueChanged}
            orgChangeUrl={orgChangeUrl}
          />
        )}
      </li>
    );
  };

  render() {
    const {
      localizedContent: { siteHeaders: content },
      hideBurgerMenu,
      darkLayout
    } = this.props;

    return (
      <header className={`${styles.siteHeader} ${darkLayout && styles.darkBackground}`}>
        {/* For keyboard & screenreader users: (Hidden accessibly. Visible on focus) */}
        <a className="a11y-hide-focusable" href="#maincontent">
          {content.skip_to_main_content}
        </a>

        <div className="grid">
          <div className="row">
            <div id="navHeader" className="col">
              <nav aria-label="Primary">
                <ul>
                  {/* Burger menu on mobile. Hidden by css on Desktop etc */}
                  {!hideBurgerMenu && this._renderBurgerMenuButton()}

                  {/* OUP Logo */}
                  <li className={styles.oxfordLogo}>{this._renderLogoLink()}</li>

                  {/* Language selector - hidden by default */}
                  {this._renderLanguageSelector()}

                  {/* Support link (Note this is replicated in SubHeader menu for xs devices:) */}
                  {/* Also note that this is hidden by CSS when the BurgerMenu is visible. */}
                  {this._renderHelpLink()}

                  {this._renderUnauthorizedUserButtons()}
                  {this._renderAccountWidget()}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export const connectRedux = connect(
  state => {
    const roleName = state.identity.role;
    const userSignedIn = !!roleName;
    const queryString = parseQueryString(window.location.search) || {};
    let currentUser = {};
    let currentOrg = {};

    if (userSignedIn) {
      currentUser = getCurrentUser(state) || {};
      currentOrg = getCurrentOrganisation(state) || {};
    }

    return {
      accountWidgetOpen: state.app.accountWidgetOpen,
      userSignedIn,
      platform: queryString.providerId || queryString.platform,
      canRegisterOrg: roleCanCreateSecondaryOrg(roleName),
      logoutUrl: getLogoutUrl(),
      userFirstName: currentUser.firstname,
      userLastName: currentUser.lastname,
      userName: currentUser.username || currentUser.email,
      userEmail: currentUser.email || '',
      friendlyUserRole: userRoleFriendlyNames(roleName),
      userRole: roleName,
      userOrganisations: getCurrentUserOrganisations(state),
      orgName: currentOrg.name || '',
      orgRole: currentOrg.role || '',
      orgConfig: currentOrg?.orgConfig || {},
      customId: currentOrg.customId,
      orgSubscriptions: state.subscriptions,
      orgSubscriptionsLoading: state.search.orgSubscriptions
        ? state.search.orgSubscriptions.loading
        : !!state.identity.currentOrganisationId &&
          state.invites.invites &&
          !(state.invites.invites.length > 0) &&
          !localStorage.getItem('showStartUpPages')
    };
  },
  (dispatch, props) => ({
    handleToggleAccountWidget: () => dispatch(toggleAccountWidget()),
    onLogout: () => {
      dispatch(toggleAccountWidget());
      dispatch(triggerLogout());
    },
    onOrgRegisterClick: () => {
      dispatch(toggleAccountWidget());
      props.history.push(`/org/register`);
    },
    onOrgChange: (orgId, returnUrl) => {
      dispatch(toggleAccountWidget());
      dispatch(switchOrg(orgId, null, returnUrl));
    },
    onEditAccountClick: () => {
      dispatch(openEditMyAccountModal());
      dispatch(toggleAccountWidget());
    },
    onLinkAccount: () => {
      dispatch(openEditMyAccountModal());
    }
  })
);

HubHeader.propTypes = {
  orgId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  /** Boolean which toggles showing the global button popup */
  accountWidgetOpen: PropTypes.bool.isRequired,
  /** True if a user is signed in, will control rendering the AccountWidget */
  userSignedIn: PropTypes.bool.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  /** Action to toggle the global popup open value */
  handleToggleAccountWidget: PropTypes.func.isRequired,
  // Sub nav props
  onToggleMenu: PropTypes.func.isRequired,
  // platform/providerId passed as a query param
  platform: PropTypes.string,
  hideWidgetDropDown: PropTypes.bool.isRequired,
  hideSignInLink: PropTypes.bool.isRequired,
  hideRegisterLink: PropTypes.bool.isRequired,
  hideHelpLink: PropTypes.bool.isRequired,
  disableLogoLink: PropTypes.bool.isRequired,
  darkLayout: PropTypes.bool.isRequired,
  canRegisterOrg: PropTypes.bool,
  onOrgRegisterClick: PropTypes.func.isRequired,
  onEditAccountClick: PropTypes.func,
  onLinkAccount: PropTypes.func,
  onOrgChange: PropTypes.func.isRequired,
  myOrgUrl: PropTypes.string,
  userId: PropTypes.string,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  orgChangeUrl: PropTypes.string,
  hideMyAccountText: PropTypes.bool,
  hideBurgerMenu: PropTypes.bool,
  hubMenuSelected: PropTypes.bool,
  orgRole: PropTypes.string,
  orgConfig: PropTypes.object,
  orgSubscriptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  orgSubscriptionsLoading: PropTypes.bool,
  friendlyUserRole: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  hasPendingInvites: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string,
  linkSocialAccount: PropTypes.bool,
  hideLanguageSelector: PropTypes.bool
};

export default compose(withLocalizedContent('siteHeaders'), withRouter, connectRedux)(HubHeader);
