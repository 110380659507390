import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
import localStorageWithCache from '../../../utils/localStorageWithCache';
import { isLtiMode } from '../../../utils/platform';
import { HubIllustrationConstants } from '../../../globals/hubConstants.js';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { featureIsEnabled } from '../../../globals/envSettings';

const getIdentityOrSelfSelectedRole = userRole => {
  if (roleIsAtLeast(userRoles.LEARNER, userRole)) {
    return userRole;
  }

  const { getKey } = localStorageWithCache;
  const { role: selfSelectedRole } = JSON.parse(getKey('selectedRoleData')) || '';

  if (!selfSelectedRole) {
    return userRole;
  }

  return selfSelectedRole;
};

const processUserRole = (userRole, content, showMyDashboardTabs = false) => {
  const role = getIdentityOrSelfSelectedRole(userRole);
  const data = {
    ORG_ADMIN: {
      CLASSES: {
        emptyStateTitle: content.no_classes_text,
        emptyStateSubtitle: content.no_classes_subtitle_text,
        showFirstEmptyStateButton: true,
        firstButtonText: content.create_class,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        isFirstButtonPrimary: true,
        showMyDashboardTabs: true,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SINGLE_CLASS: {
        showClassAssessmentButton: true,
        showAddToClassButton: true,
        showManageClassButton: true,
        showProgressButton: true,
        showPeopleButton: true,
        showClassworkButton: true,
        isPrimaryButton: true,
        emptyStateClassworkTitle: content.teacher_classwork_empty_state_title,
        emptyStateNgiClassworkTitle: content.teacher_ngi_classwork_empty_state_title,
        emptyStateClassworkSubtitle: content.teacher_classwork_empty_state_subtitle,
        showClassworkEmptyStateButton: true
      },
      PEOPLE: {
        showAddStudentsButton: true,
        showAddTeachersButton: true,
        isPrimaryButton: true,
        emptyStateTitle: content.people_page_empty_state_title,
        emptyStateSubtitle: content.people_page_empty_state_subtitle
      },
      SIDE_NAV: {
        showAllButton: true,
        showClassesTab: true,
        showMyProgressTab: false
      },
      ORG_STUDENTS: {
        showAddStudentsButton: true
      },
      COMMON: {
        showCreateClassButton: true
      }
    },
    TEACHER_ADMIN: {
      CLASSES: {
        emptyStateTitle: content.no_classes_text,
        emptyStateSubtitle: content.no_classes_subtitle_text,
        showFirstEmptyStateButton: true,
        firstButtonText: content.create_class,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        isFirstButtonPrimary: true,
        showMyDashboardTabs: true,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SINGLE_CLASS: {
        showClassAssessmentButton: true,
        showAddToClassButton: true,
        showManageClassButton: true,
        showProgressButton: true,
        showPeopleButton: true,
        showClassworkButton: true,
        emptyStateClassworkTitle: content.teacher_classwork_empty_state_title,
        emptyStateClassworkSubtitle: content.teacher_classwork_empty_state_subtitle,
        showClassworkEmptyStateButton: true
      },
      PEOPLE: {
        showAddStudentsButton: true,
        showAddTeachersButton: true,
        emptyStateTitle: content.people_page_empty_state_title,
        emptyStateSubtitle: content.people_page_empty_state_subtitle
      },
      SIDE_NAV: {
        showAllButton: true,
        showClassesTab: true,
        showMyProgressTab: false
      },
      ORG_STUDENTS: {
        showAddStudentsButton: true
      },
      COMMON: {
        showCreateClassButton: true
      }
    },
    TEACHER: {
      CLASSES: {
        emptyStateTitle: content.no_classes_text,
        emptyStateSubtitle: content.no_classes_subtitle_text,
        showFirstEmptyStateButton: true,
        firstButtonText: content.create_class,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        isFirstButtonPrimary: true,
        showMyDashboardTabs: true,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SINGLE_CLASS: {
        showClassAssessmentButton: true,
        showAddToClassButton: true,
        showManageClassButton: true,
        showProgressButton: true,
        showPeopleButton: true,
        showClassworkButton: true,
        emptyStateClassworkTitle: content.teacher_classwork_empty_state_title,
        emptyStateClassworkSubtitle: content.teacher_classwork_empty_state_subtitle,
        showClassworkEmptyStateButton: true
      },
      PEOPLE: {
        showAddStudentsButton: true,
        showAddTeachersButton: true,
        emptyStateTitle: content.people_page_empty_state_title,
        emptyStateSubtitle: content.people_page_empty_state_subtitle
      },
      SIDE_NAV: {
        showAllButton: true,
        showClassesTab: true,
        showMyProgressTab: false
      },
      COMMON: {
        showCreateClassButton: true
      }
    },
    LEARNER: {
      CLASSES: {
        emptyStateTitle: content.no_classes_student_title_text,
        emptyStateSubtitle: content.no_classes_student_subtitle_text,
        showFirstEmptyStateButton: false,
        showEmptyStateJoinClassWithCodeButton: true,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        joinClassWithCodeButtonText: content.join_class_with_code,
        showMyDashboardTabs: true,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SINGLE_CLASS: {
        showClassAssessmentButton: true,
        showAddToClassButton: false,
        showManageClassButton: false,
        showProgressButton: false,
        showPeopleButton: false,
        showClassworkButton: true,
        emptyStateClassworkTitle: isLtiMode()
          ? content.student_classwork_empty_state_title_lti
          : content.teacher_classwork_empty_state_title,
        emptyStateClassworkSubtitle: isLtiMode()
          ? content.student_classwork_empty_state_subtitle_lti
          : content.student_classwork_empty_state_subtitle,
        showClassworkEmptyStateButton: false
      },
      SIDE_NAV: {
        showAllButton: true,
        showClassesTab: true,
        showJoinClassWithCodeSideButton: !featureIsEnabled('opt-main-features'),
        showMyProgressTab: true
      },
      COMMON: {
        showCreateClassButton: false,
        showJoinClassWithCodeCommonButton: true
      }
    },
    SELF_TEACHER: {
      CLASSES: {
        emptyStateTitle: content.no_classes_self_teacher_title,
        emptyStateSubtitle: content.no_classes_self_teacher_subtitle,
        showFirstEmptyStateButton: true,
        firstButtonText: content.no_classes_self_teacher_button_text,
        firstButtonLink: content.no_classes_self_teacher_link,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        onClickFirstButton: null,
        showSecondEmptyStateButton: true,
        secondButtonText: content.no_classes_self_teacher_second_button_text,
        secondButtonLink: content.no_classes_self_teacher_second_link,
        isSecondButtonPrimary: true,
        showMyDashboardTabs: !featureIsEnabled('opt-main-features'),
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SIDE_NAV: {
        showAllButton: false,
        showClassesTab: true,
        showMyProgressTab: false,
        showNote: true
      },
      COMMON: {
        showCreateClassButton: false
      }
    },
    SELF_LEARNER: {
      CLASSES: {
        emptyStateTitle: content.no_classes_student_title_text,
        emptyStateSubtitle: content.no_classes_student_subtitle_text,
        showFirstEmptyStateButton: false,
        firstButtonIcon: GLYPHS.ICON_PLUS,
        showEmptyStateJoinClassWithCodeButton: true,
        joinClassWithCodeButtonText: content.join_class_with_code,
        showMyDashboardTabs: true,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SIDE_NAV: {
        showAllButton: true,
        showClassesTab: true,
        showJoinClassWithCodeSideButton: !featureIsEnabled('opt-main-features'),
        showMyProgressTab: true
      },
      COMMON: {
        showCreateClassButton: false,
        showJoinClassWithCodeCommonButton: true
      }
    },
    USER: {
      CLASSES: {
        emptyStateTitle: content.no_classes_text,
        emptyStateSubtitle: content.no_classes_subtitle_text,
        showFirstEmptyStateButton: false,
        showMyDashboardTabs,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS,
        ...(showMyDashboardTabs
          ? {
              firstButtonIcon: GLYPHS.ICON_PLUS,
              showEmptyStateJoinClassWithCodeButton: true,
              joinClassWithCodeButtonText: content.join_class_with_code
            }
          : {})
      },
      SIDE_NAV: {
        showAllButton: false,
        showClassesTab: false,
        showMyOrganization: false,
        showJoinClassWithCodeSideButton: !featureIsEnabled('opt-main-features'),
        showMyProgressTab: false
      },
      COMMON: {
        showCreateClassButton: false,
        showJoinClassWithCodeCommonButton: true
      }
    },
    OUP_ADMIN: {
      CLASSES: {
        emptyStateTitle: content.no_classes_text,
        emptyStateSubtitle: content.no_classes_subtitle_text,
        showFirstEmptyStateButton: false,
        showMyDashboardTabs: false,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SIDE_NAV: {
        showAllButton: false,
        showClassesTab: false,
        showMyOrganization: false,
        showMyProgressTab: false
      },
      COMMON: {
        showCreateClassButton: false
      }
    },
    OUP_SUPPORT: {
      CLASSES: {
        emptyStateTitle: content.no_classes_text,
        emptyStateSubtitle: content.no_classes_subtitle_text,
        showFirstEmptyStateButton: false,
        showMyDashboardTabs: false,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS
      },
      SIDE_NAV: {
        showAllButton: false,
        showClassesTab: false,
        showMyOrganization: false,
        showMyProgressTab: false
      },
      COMMON: {
        showCreateClassButton: false
      }
    }
  };

  return data[role];
};

export default processUserRole;
