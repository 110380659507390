import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import Button from '@oup/shared-front-end/src/components/Button';
import style from './LicenceCounting.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';

function LicenceCounting({
  localizedContent: { placementTests: placementTestsContent },
  setIsChooseTestTakerPanelOpen,
  setShowManageAddedStudentsPanel,
  setShowNumberOfPlacesForUnknownStudentsPanel,
  numberOfLicenses = 0,
  newStudents = [],
  placesForUnknownStudents = 0,
  placementTestProductChanged
}) {
  const { setIsPrimaryButtonDisabled } = useWizard();
  const [totalLicenses, setTotalLicenses] = useState(0);
  const [sessionSize, setSessionSize] = useState(0);

  useEffect(() => {
    const size = placementTestProductChanged ? 0 : sessionSize;
    setTotalLicenses(numberOfLicenses + size);
  }, [numberOfLicenses, sessionSize]);

  useEffect(() => {
    setSessionSize(newStudents.length + placesForUnknownStudents);
  }, [newStudents, placesForUnknownStudents]);

  const getLicencesMessageData = () => {
    let messageState;
    let messageText;

    if (numberOfLicenses === 0 && sessionSize === 0) {
      messageText = placementTestsContent?.add_students_validation_no_licences_warning;
      messageState = 'warning';
    } else if (sessionSize === 0) {
      messageText = placementTestsContent?.add_students_validation_licences_available;
      messageState = 'success';
    } else if (sessionSize <= totalLicenses) {
      messageText = placementTestsContent?.add_students_validation_success;
      messageState = 'success';
    } else {
      messageText = placementTestsContent?.add_students_validation_warning;
      messageState = 'warning';
    }

    return {
      messageState,
      messageText
    };
  };

  const getLicencesValidationMessageElement = (messageText, messageState) => (
    <ValidationMessage state={messageState}>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: messageText.replace('[usedLicences]', sessionSize).replace('[remainingLicences]', numberOfLicenses)
        }}
      />
      <TextLink to="#">{placementTestsContent.placement_test_add_students_page_buy_more_link_text}</TextLink>
    </ValidationMessage>
  );

  const { messageState, messageText } = getLicencesMessageData();

  useEffect(() => {
    setIsPrimaryButtonDisabled(numberOfLicenses === 0 || sessionSize === 0);
  }, [sessionSize, totalLicenses]);

  const placesForUnknownStudentsAction = () => {
    if (numberOfLicenses === 0) {
      return;
    }

    setShowNumberOfPlacesForUnknownStudentsPanel(true);
  };

  return (
    <div className={style.licenceCountingContainer}>
      <Button
        variant="outline"
        text={placementTestsContent.placement_test_add_students}
        icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
        onClick={() => setIsChooseTestTakerPanelOpen(true)}
        disabled={numberOfLicenses === 0}
      />
      <h4>
        <strong>{placementTestsContent.placement_test_add_students_page_session_size}</strong> {sessionSize}
      </h4>
      {messageText && (
        <div className={style.validationMessageContainer}>
          {getLicencesValidationMessageElement(messageText, messageState)}
        </div>
      )}
      {sessionSize > 0 && (
        <div className={style.studentsSelectorContainer}>
          <div
            className={style.studentsSelector}
            onClick={() => setShowManageAddedStudentsPanel(true)}
            onKeyDown={e => e.key === 'Enter' && setShowManageAddedStudentsPanel(true)}
            tabIndex={0}
            role="button"
          >
            <div className={style.segmentTitle}>
              <b>{placementTestsContent.placement_test_add_students_page_students_added}</b>
              <div className={style.numberOfStudentsInfo}>{newStudents.length}</div>
            </div>
            <SVGIcon glyph={GLYPHS.ICON_SMALL_BREADCRUMB_CHEVRON} className={style.chevronIcon} />
          </div>
          <hr />
          <div
            className={style.studentsSelector}
            onClick={() => placesForUnknownStudentsAction()}
            onKeyDown={e => e.key === 'Enter' && placesForUnknownStudentsAction()}
            tabIndex={0}
            role="button"
          >
            <div className={style.segmentTitle}>
              <b>{placementTestsContent.placement_test_add_students_page_places_for_unknown_students}</b>
              <div className={style.numberOfStudentsInfo}>{placesForUnknownStudents}</div>
            </div>
            {!!numberOfLicenses && (
              <SVGIcon glyph={GLYPHS.ICON_SMALL_BREADCRUMB_CHEVRON} className={style.chevronIcon} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

LicenceCounting.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setIsChooseTestTakerPanelOpen: PropTypes.func,
  setShowManageAddedStudentsPanel: PropTypes.func,
  setShowNumberOfPlacesForUnknownStudentsPanel: PropTypes.func,
  numberOfLicenses: PropTypes.number,
  newStudents: PropTypes.array,
  placesForUnknownStudents: PropTypes.number,
  placementTestProductChanged: PropTypes.bool
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      numberOfLicenses: placementTestSessionCreate.numberOfLicenses,
      newStudents: placementTestSessionCreate.placementTest.studentsList,
      placesForUnknownStudents: placementTestSessionCreate.placementTest.placesForUnknownStudents,
      placementTestProductChanged: placementTestSessionCreate.placementTestProductChanged
    }),
    {}
  )
)(LicenceCounting);
