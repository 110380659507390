import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { groupBy, get, pick } from 'lodash';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Heading from '@oup/shared-front-end/src/components/Heading';
import userRoles from '../../globals/userRoles';
import withSearchInitialiser from '../../components/SearchInitialiser/withSearchInitialiser';
import processUserRole from '../HubDashboardLayout/Services/processUserRole';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
import withHubList from './withHubList';
import withBreakpoint from '../../decorators/withBreakpoint';
import { isLtiMode } from '../../utils/platform';
import { featureIsEnabled } from '../../globals/envSettings';
// Constants
import {
  getOrgStudentsHeaderItems,
  getOrgStudentsFooterActionButtons,
  HubLayoutConstants,
  MAX_PEOPLE,
  INSTANCE_ROLES,
  HubIllustrationConstants,
  HubIllustrationAltText
} from '../../globals/hubConstants';
import colors from '../../globals/colors';
import breakpoints from '../../globals/breakpoints';
// Components
import HubListItem from './HubListItem';
import PaginationButtons from '../../components/PaginationButtons/PaginationButtons';
import PeopleFooter from '../HubClassLayout/People/PeopleFooter';
import SubSectionSkeletonLoader from '../../components/SkeletonLoader/SubSectionSkeletonLoader';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import HubFilterBar from '../../components/HubFilterBar/HubFilterBar';
import HubFilterBarSkeleton from '../../components/HubFilterBar/HubFilterBarSkeleton';

// Redux
import {
  setStudentSelected,
  setSelectAllStudents,
  checkIfAllStudentsAreSelected,
  clearSelectedUser,
  setAllStudentsSelected,
  resetOrganisationPage
} from '../../redux/reducers/organisationPage.reducer';
import {
  setPageOrgStudent,
  initialiseInstance,
  setFilter,
  setSort,
  setTerm
} from '../../redux/reducers/data/search.reducer';
// Services
import getPeopleCheckboxData from '../HubDashboardLayout/Services/getPeopleCheckboxData';
import withDataRecency from '../../dataRecency/withDataRecency';
import { peopleSortOptions, peopleFilterOptions, peopleFilterOptionsForLti } from '../../globals/hubSearchFilter';
import initialiseDefaultSearch from '../../utils/initialiseDefaultSearch';

const EnhancedHubListPage = withHubList(HubListItem);

class HubListPageStudents extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { filtersEmptyState: false };
  }

  componentWillUnmount() {
    const { resetOrganisationPageAction } = this.props;

    resetOrganisationPageAction();
  }

  navigateToProfile = id => {
    const { push } = this.props;

    push(
      `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}/${id}/learningMaterial`
    );
  };

  _getStudentsContextName = () => {
    const {
      people,
      selectedStudentIds,
      localizedContent: { productFinder: productFinderContent }
    } = this.props;
    return selectedStudentIds.length === 1
      ? `${people[selectedStudentIds[0]]?.firstname || ''} ${people[selectedStudentIds[0]]?.lastname || ''}`
      : `${selectedStudentIds.length} ${productFinderContent.students_text}`;
  };

  render() {
    const {
      orgTitle,
      studentIds,
      people,
      localizedContent: { mySchoolStudentsTab, hubGlossary: hubContent },
      currentUsersList,
      breakpoint,
      selectedStudentIds,
      areStudentsSelected,
      setStudentSelectedAction,
      setSelectAllStudentsAction,
      checkIfAllStudentsAreSelectedAction,
      setAllStudentsSelectedAction,
      totalResults,
      page,
      setPageAction,
      userRole,
      loadingResults,
      actions,
      userDropdownActions,
      isOrgModalOpen,
      orgId,
      initialiseSearch,
      peopleFilters,
      setFilterAction,
      setSortAction,
      setSearchTermAction,
      currentOrganisationLti
    } = this.props;
    const existingStudentsInOrg = Object.values(people).filter(person => person.roleName === userRoles.LEARNER);
    const classPageTitle = `${orgTitle} | ${hubContent.people_header_student_text}`;
    const { ORG_STUDENTS: { showAddStudentsButton = false } = {} } = processUserRole(userRole, hubContent);

    const emptyStateData =
      existingStudentsInOrg.length && !totalResults
        ? {
            iconSrc: HubIllustrationConstants.SEARCH,
            iconAlt: HubIllustrationAltText.SEARCH,
            title: `${hubContent.organization_page_filters_empty_state_title}`,
            subtitle: hubContent.organization_page_filters_empty_state_subtitle,
            customClassName: 'classPageFilteredEmptyState',
            button: {
              text: hubContent.organization_page_filters_reset,
              icon: GLYPHS.ICON_REFRESH,
              action: () => {
                initialiseDefaultSearch(INSTANCE_ROLES.ORG_STUDENTS, initialiseSearch, orgId);
                this.setState({
                  // eslint-disable-next-line react/no-unused-state
                  filtersEmptyState: false,
                  searchTerm: ''
                });
              }
            },
            buttonAdd: {
              isPrimaryButton: true,
              text: hubContent.addStudents,
              icon: GLYPHS.ICON_PLUS,
              action: e => {
                actions.addStudents(e, HubLayoutConstants.PEOPLE_PANEL_TYPES.ADD_STUDENTS, {
                  selectedUserId: '',
                  context: APP_CONSTANTS.ORG_STUDENTS,
                  role: userRoles.LEARNER
                });
              }
            }
          }
        : {
            iconSrc: HubIllustrationConstants.EMAILS_SENT,
            iconAlt: 'Emails sent illustration',
            title: currentOrganisationLti ? '' : hubContent.student_page_empty_state_title,
            subtitle: currentOrganisationLti
              ? hubContent.student_page_empty_state_subtitle_ltihub
              : hubContent.student_page_empty_state_subtitle,
            button:
              showAddStudentsButton && !currentOrganisationLti
                ? {
                    icon: GLYPHS.ICON_PLUS,
                    text: hubContent.add_students,
                    action: e =>
                      actions.addStudents(e, HubLayoutConstants.PEOPLE_PANEL_TYPES.ADD_STUDENTS, {
                        selectedUserId: '',
                        context: APP_CONSTANTS.ORG_STUDENTS,
                        role: userRoles.LEARNER
                      })
                  }
                : null
          };

    const setFilterActionStudents = (valueName, value) => {
      setFilterAction('orgStudents', valueName, value);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        filtersEmptyState: true
      });
    };

    const setSortActionStudents = sort => {
      setSortAction('orgStudents', sort[0]);
    };

    const setSearchTermActionStudents = searchTerm => {
      setSearchTermAction('orgStudents', searchTerm);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        searchTerm
      });
    };
    const { searchTerm } = this.state;
    const sortValue = peopleFilters.sortStudents;

    const customFilterComponent = <HubFilterBarSkeleton />;
    const showHeading = featureIsEnabled('navigation-changes');
    const headingArgs = {
      text: hubContent.people_header_student_text,
      size: 'small'
    };
    return (
      <div>
        <Helmet title={classPageTitle} />
        {showHeading && <Heading {...headingArgs} />}
        <DataRefresher
          loading={loadingResults}
          noSidePadding={showHeading}
          refreshData={() => {
            initialiseSearch(
              INSTANCE_ROLES.ORG_STUDENTS,
              true,
              {
                active: 'active' in peopleFilters.filtersStudents ? peopleFilters.filtersStudents.active : true,
                archived: 'archived' in peopleFilters.filtersStudents ? peopleFilters.filtersStudents.archived : true,
                invited: 'invited' in peopleFilters.filtersStudents ? peopleFilters.filtersStudents.invited : true,
                roles: [userRoles.LEARNER],
                isOrgStudentTab: true,
                orgId,
                hubPlatform: true
              },
              MAX_PEOPLE,
              'lastName:asc'
            );
          }}
        />
        <div>
          {existingStudentsInOrg.length > 0 && (
            <HubFilterBar
              idPrefix="organizationStudentSearch"
              filterButtonText={breakpoint !== breakpoints.XXS ? hubContent.status_button : hubContent.filter_button}
              overlayLabelText={hubContent.status_button}
              breakpoint={breakpoint}
              filterOptions={(isLtiMode() ? peopleFilterOptionsForLti : peopleFilterOptions)(
                'classPeopleSearch',
                [
                  peopleFilters.filtersStudents.active,
                  peopleFilters.filtersStudents.invited,
                  peopleFilters.filtersStudents.archived
                ],
                setFilterActionStudents,
                hubContent
              )}
              sortOnChange={setSortActionStudents}
              sortOptions={peopleSortOptions('classPeopleSearch', sortValue, hubContent)}
              ariaControls="searchResults"
              sortValue={sortValue}
              searchTermOnChange={searchString => setSearchTermActionStudents(searchString)}
              withSearchBar
              searchTerm={searchTerm}
              searchPlaceholder={hubContent.search_org_students_placeholder}
            />
          )}
        </div>
        {loadingResults ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
            customEnhancedComponent={customFilterComponent}
          />
        ) : (
          <div>
            <div>
              <EnhancedHubListPage
                lists={[studentIds]}
                headerItems={getOrgStudentsHeaderItems(mySchoolStudentsTab, hubContent, actions)}
                currentUsersList={currentUsersList}
                breakpoint={breakpoint}
                hubContent={hubContent}
                userRole={userRole}
                selectedStudentIds={selectedStudentIds}
                areStudentsSelected={areStudentsSelected}
                checkboxData={getPeopleCheckboxData(
                  'LEARNER',
                  setStudentSelectedAction,
                  selectedStudentIds,
                  setSelectAllStudentsAction,
                  areStudentsSelected,
                  checkIfAllStudentsAreSelectedAction,
                  setAllStudentsSelectedAction
                )}
                hasHeaderButtonAccess={
                  !currentOrganisationLti && (userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN)
                }
                emptyStateData={emptyStateData}
                totalResults={totalResults}
                actions={userDropdownActions}
                onClick={this.navigateToProfile}
                dropdownType="orgStudents"
                showCheckboxHeader={!isLtiMode()}
                showDropdown={!isLtiMode()}
                currentOrganisationLti={currentOrganisationLti}
              />
              {totalResults > 10 && (
                <div className="gin-top2">
                  <PaginationButtons
                    idPrefix="orgStudentSearch"
                    value={page}
                    numberOfPages={Math.ceil(totalResults / 10)}
                    onClick={setPageAction}
                    aria={{ 'aria-controls': 'searchResults' }}
                  />
                </div>
              )}
              <PeopleFooter
                widgetText={hubContent.chosen_text}
                breakpoint={breakpoint}
                actions={getOrgStudentsFooterActionButtons(hubContent, userRole, actions, currentOrganisationLti)}
                isModalOpen={isOrgModalOpen}
                selectedUsers={selectedStudentIds}
                contextName={this._getStudentsContextName()}
                context={APP_CONSTANTS.ORG_STUDENTS}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withSearchInitialiser({
    searchSource: 'orgStudents',
    isAsYouType: true,
    initialFilters: {
      roles: [userRoles.LEARNER],
      active: true,
      invited: true,
      archived: false,
      isOrgStudentTab: true, // check for orgStudentTab
      hubPlatform: true
    }
  }),
  withLocalizedContent(
    'mySchoolStudentsTab',
    'removeLearningMaterialModal',
    'managedUserSignInCardPanel',
    'hubGlossary',
    'productFinder'
  ),
  withBreakpoint,
  withDataRecency('orgStudents'),
  connect(
    (state, { orgId, hubContent }) => {
      const myOrganization = get(state, ['organisations', 'data', [orgId]]);
      const paginatedStudentIds = Object.keys(state.search.orgStudents.paginatedUserList) || [];
      // managed role and learner
      const studentrole = id => get(state, ['search', 'orgStudents', 'paginatedUserList', [id], 'roleName']);
      // Fit to withHubList
      const { [userRoles.LEARNER]: studentIds = [] } = groupBy(paginatedStudentIds, studentrole);

      const sortStudents = get(state, ['search', 'orgStudents', 'sort'], 'lastName:asc');
      const pageStudents = get(state, ['search', 'orgStudents', 'page'], 1);
      const filtersStudents = get(state, ['search', 'orgStudents', 'filters'], {});
      const totalResultsStudents = get(state, ['search', 'orgStudents', 'totalResults'], 0);
      const errorStudents = get(state, ['search', 'orgStudents', 'error'], false);

      return {
        orgTitle: get(myOrganization, 'name', hubContent.my_organization),
        ...pick(state.search.orgStudents, ['page', 'totalResults']),
        studentIds,
        people: state.people.data,
        currentUsersList: get(state, ['search', 'orgStudents', 'currentUsersList']),
        selectedStudentIds: get(state, ['organisationPage', 'selectedStudentIds'], []),
        areStudentsSelected: get(state, ['organisationPage', 'areStudentsSelected'], false),
        userRole: get(state, ['identity', 'role']),
        orgId: state.identity.currentOrganisationId,
        currentOrganisationLti: state.identity.currentOrganisationLti,
        userId: state.identity.userId,
        loadingResults: state.search.orgStudents ? state.search.orgStudents.loading : false,
        isOrgModalOpen: state.hubUi.orgModalOpen,
        peopleFilters: {
          sortStudents,
          pageStudents,
          filtersStudents,
          totalResultsStudents,
          errorStudents
        }
      };
    },
    {
      setStudentSelectedAction: setStudentSelected,
      setSelectAllStudentsAction: setSelectAllStudents,
      checkIfAllStudentsAreSelectedAction: checkIfAllStudentsAreSelected,
      setAllStudentsSelectedAction: setAllStudentsSelected,
      resetOrganisationPageAction: resetOrganisationPage,
      clearSelectedUserAction: clearSelectedUser,
      initialiseSearch: initialiseInstance,
      setPageAction: page => setPageOrgStudent('orgStudents', page),
      setFilterAction: setFilter,
      setSortAction: setSort,
      setSearchTermAction: setTerm
    }
  )
)(HubListPageStudents);

HubListPageStudents.propTypes = {
  orgTitle: PropTypes.string,
  studentIds: PropTypes.array,
  people: PropTypes.object,
  localizedContent: PropTypes.object,
  currentUsersList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  breakpoint: PropTypes.string,
  selectedStudentIds: PropTypes.array,
  areStudentsSelected: PropTypes.bool,
  setStudentSelectedAction: PropTypes.func,
  setSelectAllStudentsAction: PropTypes.func,
  resetOrganisationPageAction: PropTypes.func,
  checkIfAllStudentsAreSelectedAction: PropTypes.func,
  totalResults: PropTypes.number,
  page: PropTypes.number,
  orgId: PropTypes.string,
  setPageAction: PropTypes.func,
  userRole: PropTypes.string,
  loadingResults: PropTypes.bool,
  userDropdownActions: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  push: PropTypes.func,
  isOrgModalOpen: PropTypes.bool,
  initialiseSearch: PropTypes.func,
  peopleFilters: PropTypes.object,
  setFilterAction: PropTypes.func,
  setSortAction: PropTypes.func,
  setSearchTermAction: PropTypes.func,
  currentOrganisationLti: PropTypes.bool,
  setAllStudentsSelectedAction: PropTypes.func
};
