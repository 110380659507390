import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';

import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import PlacementTestStartEndDate from './PlacementTestStartEndDate';
import PlacementTestExtraTime from './PlacementTestExtraTime';
import { setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../language/withLocalizedContent';

function PlacementTestSchedule({
  placementTestSessionNameValue,
  testStartDate = '',
  testEndDate = '',
  extraTimePerItem = 0,
  testStartDateIsInvalid,
  testEndDateIsInvalid,
  extraTimePerItemIsInvalid,
  setEditedPlacementTestAction,
  placementTestSessionIdCreated,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { handleStep, isLoading, setIsPrimaryButtonDisabled, nextStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  setIsPrimaryButtonDisabled(isLoading || testStartDateIsInvalid || testEndDateIsInvalid || extraTimePerItemIsInvalid);

  handleStep(async () => {
    setStepOut(true);
    setEditedPlacementTestAction({ testStartDate, testEndDate, extraTimePerItem });
    await animationTimeout();
  });

  useEffect(() => {
    if (placementTestSessionIdCreated) {
      nextStep();
    }
  }, [placementTestSessionIdCreated]);

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SCHEDULE_CONTAINER" className={`${fadeOut}`}>
      <WizardStep
        titleText={`${placementTestsContent.create_placement_test_schedule_title} ${placementTestSessionNameValue}`}
      >
        <PlacementTestStartEndDate />
        <PlacementTestExtraTime />
      </WizardStep>
    </div>
  );
}

PlacementTestSchedule.propTypes = {
  placementTestSessionNameValue: PropTypes.string.isRequired,
  testStartDate: PropTypes.string.isRequired,
  testEndDate: PropTypes.string.isRequired,
  extraTimePerItem: PropTypes.number.isRequired,
  testStartDateIsInvalid: PropTypes.bool,
  testEndDateIsInvalid: PropTypes.bool,
  extraTimePerItemIsInvalid: PropTypes.bool,
  setEditedPlacementTestAction: PropTypes.func,
  placementTestSessionIdCreated: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      testStartDateIsInvalid: placementTestSessionCreate.testStartDateIsInvalid,
      testEndDateIsInvalid: placementTestSessionCreate.testEndDateIsInvalid,
      extraTimePerItemIsInvalid: placementTestSessionCreate.extraTimePerItemIsInvalid,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestSchedule);
