import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import Button from '@oup/shared-front-end/src/components/Button';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';

import EntityRepresentation from '../../../EntityRepresentation/EntityRepresentation.js';
import HubEmptyStateRestyle from '../../../HubEmptyStateRestyle/HubEmptyStateRestyle';
import ScrollContainer from '../../../ScrollContainer/ScrollContainer.js';
import { SIZES as thumbnailSizes } from '../../../Thumbnail/Thumbnail.js';
import colors, { COLOR_FOR_ROLE } from '../../../../globals/colors.js';
import { toInitials } from '../../../../utils/string';
import content from '../../../../utils/cmsContent.js';
import ContentWithSVGIcon from '../../../ContentWithSVGIcon/ContentWithSVGIcon';
import SVGIcon, { GLYPHS } from '../../../SVGIcon/SVGIcon';
import { getDayMonthYearFormat } from '../../../../globals/dateFormats';
import PanelHeading from '../../../PanelHeading/PanelHeading.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PanelSearchControl from '../../../PanelSearchControl/PanelSearchControl';
import PaginationButtons from '../../../PaginationButtons/PaginationButtons.js';
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../../../../globals/hubConstants';
import styles from './ManageAddedStudentsPanel.scss';

function ManageAddedStudentsPanel({
  placementTestSessionName,
  cancelPanel,
  onAddStudents,
  studentsDetails = {},
  failed,
  isManageSessionEditPanel,
  localizedContent: {
    placementTests: placementTestsContent,
    studentSelectionPanel: studentSelectionPanelContent,
    hubGlossary: hubContent
  }
}) {
  const [currentPage, sertCurrentPage] = useState(1);

  const CMS = content.enrolUserPanel;
  const students = Object.entries(studentsDetails);

  const totalResults = students.length;

  const studentsPerPage = 10;
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students.slice(indexOfFirstStudent, indexOfLastStudent);

  const paginate = pageNumber => sertCurrentPage(pageNumber);

  return (
    <ScrollContainer
      headerContent={
        <>
          <PanelHeading
            title={
              isManageSessionEditPanel
                ? placementTestsContent.manage_session_manage_students_panel_title
                : placementTestsContent.manage_added_students_panel_title
            }
            subtitle={
              isManageSessionEditPanel
                ? placementTestsContent.manage_session_manage_students_panel_subtitle.replace(
                    '[sessionName]',
                    `<b>${placementTestSessionName}</b>`
                  )
                : placementTestsContent.manage_added_students_panel_subtitle.replace(
                    '[sessionName]',
                    `<b>${placementTestSessionName}</b>`
                  )
            }
          />
          {students.length ? (
            <PanelSearchControl
              panelType={HubLayoutConstants.FILTER_PANEL_TYPES.ADD_STUDENTS_CLASS}
              filterOptions={[
                { text: 'Username', value: 'username' },
                { text: 'Name', value: 'name' }
              ]}
              sortOptions={[
                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' }
              ]}
              placeholder={studentSelectionPanelContent.search_bar_placeholder_secondary}
            />
          ) : null}
        </>
      }
      footerContent={
        isManageSessionEditPanel ? (
          <Footer
            primaryButtonLabel={placementTestsContent.placement_test_remove_students}
            secondaryButtonLabel={hubContent.cancel}
            primaryButtonAction={{}}
            secondaryButtonAction={cancelPanel}
            primaryButtonAttributes={{
              dataAttributes: {
                testid: 'PLACEMENT_TEST_PANEL_MANAGE_STUDENTS_REMOVE_STUDENTS_BUTTON'
              },
              disabled: true
            }}
            secondaryButtonAttributes={{
              dataAttributes: {
                testid: 'PLACEMENT_TEST_PANEL_MANAGE_STUDENTS_REMOVE_STUDENTS_CANCEL_BUTTON'
              }
            }}
          />
        ) : null
      }
    >
      {!failed && students.length ? (
        <div className={styles.manageAddedStudentsContainer}>
          <ValidationMessage state="information" className={styles.validationMessage}>
            {placementTestsContent.manage_added_students_panel_information_message}
          </ValidationMessage>
          <div className={styles.manageAddedStudentAddBtnContainer}>
            <h4>Students:</h4>
            {isManageSessionEditPanel && (
              <Button
                onClick={onAddStudents}
                text={hubContent.add_placement_test_button}
                variant="filled"
                size="small"
                icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
                dataAttributes={{ testid: 'MANAGE_SESSION_MANAGE_STUDENTS_PANNEL_ADD_BTN' }}
              />
            )}
          </div>

          {currentStudents.map(
            ([userId, { firstname, lastname, email, usernameGenerated, usernameWithoutOrg, roleName }]) => (
              <ContentWithSVGIcon key="student">
                {usernameGenerated ? (
                  <EntityRepresentation
                    userUpdateSuccess
                    id={`enrolUserConfirmationEntity-${userId}`}
                    thumbnailSize={thumbnailSizes.MEDIUM}
                    thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                    thumbnailText={toInitials(firstname, lastname)}
                    line1Text={`${firstname} ${lastname}`}
                    line1Color={colors.BLACK_COLOR}
                    line2Text={usernameWithoutOrg}
                    status="TEXT"
                  />
                ) : (
                  <EntityRepresentation
                    id={`enrolUserConfirmationEntity-${userId}`}
                    thumbnailSize={thumbnailSizes.MEDIUM}
                    thumbnailColor={COLOR_FOR_ROLE[roleName] || COLOR_FOR_ROLE.USER}
                    thumbnailText={toInitials(firstname, lastname)}
                    line1Text={`${firstname} ${lastname}`}
                    line1Color={colors.BLACK_COLOR}
                    line2Text={email}
                    line3Html={CMS.confirmation_entity_added_email.replace(
                      '{date}',
                      `<strong>${getDayMonthYearFormat(new Date())}</strong>`
                    )}
                    specificGlyph={GLYPHS.ICON_INVITED}
                    status="TEXT"
                  />
                )}
              </ContentWithSVGIcon>
            )
          )}

          {totalResults > 10 ? (
            <div className="gin-top2">
              <PaginationButtons
                idPrefix="placementTestStudentsSearch"
                value={currentPage}
                numberOfPages={Math.ceil(totalResults / 10)}
                onClick={paginate}
                aria={{ 'aria-controls': 'searchPlacementTestStudentsResults' }}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <HubEmptyStateRestyle
          iconSrc={HubIllustrationConstants.SEARCH}
          iconAlt={HubIllustrationAltText.SEARCH}
          title={studentSelectionPanelContent.no_students_in_org_title}
          bodyText={placementTestsContent.manage_added_students_panel_information_message}
        />
      )}
    </ScrollContainer>
  );
}

ManageAddedStudentsPanel.propTypes = {
  cancelPanel: PropTypes.func,
  onAddStudents: PropTypes.func,
  placementTestSessionName: PropTypes.string.isRequired,
  failed: PropTypes.bool.isRequired,
  studentsDetails: PropTypes.array,
  isManageSessionEditPanel: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('hubGlossary', 'placementTests', 'studentSelectionPanel'))(
  ManageAddedStudentsPanel
);
