import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import PageHero from '@oup/shared-front-end/src/components/PageHero';

import SecondaryNavigation from '@oup/shared-front-end/src/components/SecondaryNavigation';

import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import PlacementTestItem from '../../structure/HubOrganizationLayout/OrganizationPlacementTests/PlacementTest.js';
import styles from './MyTasks.scss';
import { loadPlacementTestsRequest } from '../../redux/actions/placementTests.js';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner.js';
import calcDropdownPosition from '../../structure/HubOrganizationLayout/Utils/calcDropdownPosition.js';
import { openPlacementTestSessionInformationModal } from '../../redux/actions/hubUi';
import { continueEditedPlacementTest } from '../../redux/reducers/placementTestSessionCreate';

const getClassIdfromQuery = location => {
  if (!location) return '';
  const { search } = location;
  const classId = search.split('=').at(1);
  return classId;
};

function MyTasks({
  hubContent,
  userRole,
  currentOrganisationId,
  getPlacementTestsAction,
  arePlacementTestsLoading,
  orgName,
  placementTests,
  openPlacementTestSessionInformationModalAction,
  continueEditedPlacementTestAction
}) {
  const initialEmptyStateText = {
    title: hubContent.my_tasks_to_do_tab_empty_state_title || '',
    bodyText: hubContent.my_tasks_to_do_tab_empty_state_subtitle || ''
  };

  const initialtabTitle = hubContent.my_tasks_to_do_tab;

  const [emptyStateText, setEmptyStateText] = useState(initialEmptyStateText);
  const [tabTitle, setTabTitle] = useState(initialtabTitle);
  const params = useParams();
  const location = useLocation();

  let placementTest = null;
  const classId = getClassIdfromQuery(location);

  if (classId) {
    placementTest = placementTests.find(test => test._id === classId);
  }

  const loadEmptyState = placementTests.length <= 0 && !arePlacementTestsLoading;
  const loadPlacementTests = placementTests.length > 0 && !arePlacementTestsLoading;

  const _getPlacementTests = filters => {
    const payload = {
      orgId: currentOrganisationId,
      status: filters,
      sortBy: 'testEndDate:asc',
      groupPlacementTests: false
    };
    getPlacementTestsAction(payload);
  };

  useEffect(() => {
    if (placementTest) {
      continueEditedPlacementTestAction({
        placementTestSessionId: placementTest._id,
        placementTestSessionNameValue: placementTest.classGroup.className,
        joiningCode: placementTest?.code,
        placementTest: placementTest.configurations.CONFIGURATION_PLACEMENT_TEST
      });
      openPlacementTestSessionInformationModalAction();
    }
  }, [placementTest]);

  useEffect(() => {
    const { view = 'todo' } = params;

    switch (view) {
      case 'missing':
        _getPlacementTests(['COMPLETED']);
        setEmptyStateText({
          title: hubContent.my_tasks_missing_tab_empty_state_title,
          bodyText: hubContent.my_tasks_missing_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent?.my_tasks_missing_tab);
        break;
      case 'completed':
        _getPlacementTests(['COMPLETED']);
        setEmptyStateText({
          title: hubContent.my_tasks_completed_tab_empty_state_title || '',
          bodyText: hubContent.my_tasks_completed_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent?.my_tasks_completed_tab);
        break;
      case 'upcoming':
        _getPlacementTests(['UPCOMING']);
        setEmptyStateText({
          title: hubContent.my_tasks_upcoming_tab_empty_state_title || '',
          bodyText: hubContent.my_tasks_upcoming_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent.my_tasks_upcoming_tab);
        break;
      default:
        _getPlacementTests(['ACTIVE']);
        setEmptyStateText({
          title: hubContent.my_tasks_to_do_tab_empty_state_title || '',
          bodyText: hubContent.my_tasks_to_do_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent.my_tasks_to_do_tab);
        break;
    }
  }, [params]);

  const tasksTabs = [
    {
      primaryText: hubContent.my_tasks_to_do_tab,
      to: '/myTasks',
      component: RouterLink,
      ariaAttributes: {
        current: !params.view
      },
      ...(placementTests.length ? { textBadge: placementTests.length } : {})
    },
    {
      primaryText: hubContent.my_tasks_upcoming_tab,
      to: '/myTasks/upcoming',
      component: RouterLink,
      ariaAttributes: {
        current: params.view === 'upcoming'
      }
    },
    {
      primaryText: hubContent.my_tasks_completed_tab,
      to: '/myTasks/completed',
      component: RouterLink,
      ariaAttributes: {
        current: params.view === 'completed'
      }
    },
    {
      primaryText: hubContent.my_tasks_missing_tab,
      to: '/myTasks/missing',
      component: RouterLink,
      ariaAttributes: {
        current: params.view === 'missing'
      }
    }
  ];

  return (
    <div className={styles.tasks}>
      <Helmet title={hubContent.my_tasks} titleTemplate={hubContent.page_title_template} />
      <div className={styles.banner}>
        <PageHero
          title={hubContent?.my_tasks}
          illustration={HubIllustrationConstants.DIGITAL_LEARNING}
          illusAltText="my tasks hero image"
          className={styles.kipperYellow}
        />
      </div>
      <SecondaryNavigation links={tasksTabs} />
      <div className={styles.content}>
        {loadEmptyState && (
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.SEARCH}
            iconAlt={HubIllustrationAltText.SEARCH}
            {...emptyStateText}
          />
        )}
        {loadPlacementTests && (
          <>
            <h2>{tabTitle}</h2>
            <h4>{hubContent?.placementTest}</h4>
            <ul data-testid="studentTasksList">
              {placementTests.map((test, itemIndex) => {
                let status = test?.configurations?.CONFIGURATION_PLACEMENT_TEST?.status;
                if (
                  params.view === 'missing' &&
                  !test.configurations.CONFIGURATION_PLACEMENT_TEST.score &&
                  new Date(test.configurations.CONFIGURATION_PLACEMENT_TEST.testEndDate) < new Date()
                )
                  status = 'MISSING';
                return (
                  <PlacementTestItem
                    key={test._id}
                    test={test}
                    userRole={userRole}
                    id={test._id}
                    customOrgId={currentOrganisationId}
                    orgName={orgName}
                    isMyTasksPage
                    dropdownTop={calcDropdownPosition(placementTests, itemIndex)}
                    displayStatus={status === 'ACTIVE'}
                  />
                );
              })}
            </ul>
          </>
        )}
        {arePlacementTestsLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default compose(
  connect(
    ({
      identity: { role: userRole, currentOrganisationId },
      loadPlacementTestsReducer: { placementTests, loading: arePlacementTestsLoading },
      organisations: { data: orgData }
    }) => ({
      userRole,
      currentOrganisationId,
      placementTests,
      arePlacementTestsLoading,
      orgName: get(orgData, [currentOrganisationId, 'name'], '')
    }),
    {
      getPlacementTestsAction: loadPlacementTestsRequest,
      openPlacementTestSessionInformationModalAction: openPlacementTestSessionInformationModal,
      continueEditedPlacementTestAction: continueEditedPlacementTest
    }
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyTasks);

MyTasks.propTypes = {
  hubContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  selectMyTasksViewAction: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  placementTests: PropTypes.array,
  getPlacementTestsAction: PropTypes.func,
  arePlacementTestsLoading: PropTypes.bool,
  orgName: PropTypes.string,
  openPlacementTestSessionInformationModalAction: PropTypes.func,
  continueEditedPlacementTestAction: PropTypes.func
};
